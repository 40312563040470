import './StokBahan.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  QRCode,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PrinterOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import * as stokBahanApi from '../../stores/business/StokBahanApi';
import * as masterDataApi from '../../stores/business/MasterDataApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalQr, setIsOpenModalQr] = useState(false);
  const [rollMode, setRollMode] = useState("add");
  const [loading, setLoading] = useState(false);
  const [loadingRoll, setLoadingRoll] = useState(false);
  const [data, setData] = useState();
  const [dataRoll, setDataRoll] = useState();
  const [qrData, setQrData] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('jenisBahan');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState({
    id: null,
    jenisBahan: "",
    ketebalanBahan: "",
    gramasi: "",
    warnaBahan: "",
    settingBahan: "",
    jenisSettingBahan: "",
    totalBeratRoll: "",
    createdAt: null,
  });
  const [selectedDataRoll, setSelectedDataRoll] = useState({
    id: null,
    beratRoll: null,
  });
  const [filterDropdownMasterBahan, setFilterDropdownMasterBahan] = useState([
    {
      field: "jenis",
      value: ""
    },
    {
      field: "ketebalan",
      value: ""
    },
    {
      field: "gramasi",
      value: ""
    },
    {
      field: "warna",
      value: ""
    },
    {
      field: "setting",
      value: ""
    }
  ]);
  const [dropdownJenisBahan, setDropdownJenisBahan] = useState();
  const [dropdownKetebalanBahan, setDropdownKetebalanBahan] = useState();
  const [dropdownGramasiBahan, setDropdownGramasiBahan] = useState();
  const [dropdownWarnaBahan, setDropdownWarnaBahan] = useState();
  const [dropdownSettingBahan, setDropdownSettingBahan] = useState();
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const filterOptions = [
    {value: 'jenisBahan', label: 'Jenis Bahan'},
    {value: 'warnaBahan', label: 'Warna Bahan'},
    {value: 'settingBahan', label: 'Setting Bahan'},
    {value: 'jenisSettingBahan', label: 'Jenis Setting Bahan'},
    {value: 'ketebalanBahan', label: 'Ketebalan Bahan'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
    },
    {
      title: 'Warna Bahan',
      dataIndex: 'warnaBahan',
      key: 'warnaBahan',
      sorter: true,
    },
    {
      title: 'Setting Bahan',
      dataIndex: 'settingBahan',
      key: 'settingBahan',
      sorter: true,
    },
    {
      title: 'Jenis Setting Bahan',
      dataIndex: 'jenisSettingBahan',
      key: 'jenisSettingBahan',
      sorter: true,
    },
    {
      title: 'Ketebalan Bahan',
      dataIndex: 'ketebalanBahan',
      key: 'ketebalanBahan',
      sorter: true,
      render: (_, record) => (
        record.ketebalanBahan + "s"
      ),
    },
    {
      title: 'Jumlah Berat',
      dataIndex: 'totalBeratRoll',
      key: 'totalBeratRoll',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<QrcodeOutlined />} onClick={() => {showQr(record);}}/>
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsRoll = [
    {
      title: 'Jumlah Berat',
      dataIndex: 'beratRoll',
      key: 'beratRoll',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEditRoll(record)}}/>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(stokBahanApi.getPageStokBahan({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      var list = response.data.contents;
      list = list.map(obj => ({
        ...obj,
        ketebalanBahan: String(obj.ketebalanBahan),
        gramasi: String(obj.gramasi),
        settingBahan: String(obj.settingBahan),
        totalRoll: obj.totalRoll ? String(obj.totalRoll) : "0",
        totalBeratRoll: obj.totalBeratRoll ? String(obj.totalBeratRoll) : "0",
      }));
      setData(list);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDropdown = async (tipe) => {
    let list = filterDropdownMasterBahan;
    list = list.map(obj => {
      if (obj.field === "ketebalan") {
        obj.value = obj.value ? obj.value.replace("s", "") : "";
      }
      return obj;
    });
    const response = await dispatch(masterDataApi.getDropdownMasterBahan(tipe, list));
    if (response.status === 200) {
      if (tipe === "jenis-bahan") {
        setDropdownJenisBahan(response.data);
      } else if (tipe === "ketebalan-bahan") {
        setDropdownKetebalanBahan(response.data);
      } else if (tipe === "gramasi") {
        setDropdownGramasiBahan(response.data);
      } else if (tipe === "warna-bahan") {
        setDropdownWarnaBahan(response.data);
      } else if (tipe === "setting-bahan") {
        setDropdownSettingBahan(response.data);
      } 
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchCalculation = async () => {
    selectedData.id = selectedData.id ? selectedData.id : 0;
    const response = await dispatch(stokBahanApi.getCalculation(selectedData.id));
    if (response.status === 200) {
      setDataRoll([{
        id: selectedData.id,
        beratRoll: response.data.totalBeratRoll,
      }]);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onSelectTableChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const resetForm = () => {
    setSelectedData({
      id: null,
      jenisBahan: "",
      ketebalanBahan: "",
      gramasi: "",
      warnaBahan: "",
      settingBahan: "",
      jenisSettingBahan: "",
      totalRoll: "",
      totalBeratRoll: "",
      createdAt: null,
    });
    form.setFieldsValue({
      id: null,
      jenisBahan: "",
      ketebalanBahan: "",
      gramasi: "",
      warnaBahan: "",
      settingBahan: "",
      jenisSettingBahan: "",
      totalRoll: "",
      totalBeratRoll: "",
      createdAt: null,
    });
  }

  const fillForm = (record) => {
    setSelectedData({
      ...record,
      settingBahan: record.settingBahan ? record.settingBahan.replace(" Inch", "").replace(" Yard", "") + " " + record.jenisSettingBahan : null,
    });
    form.setFieldsValue({
      ...record,
      settingBahan: record.settingBahan ? record.settingBahan.replace(" Inch", "").replace(" Yard", "") + " " + record.jenisSettingBahan : null,
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectTableChange,
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this stok bahan?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(stokBahanApi.deleteStok(record.id));
    if (response.status === 200) {
      resetForm();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete stok bahan!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const print = () => {
    let baseUrlFe = window.location.href;
    let urlFe = baseUrlFe + "/mobile";

    var ids = selectedRowKeys.map(obj => obj).join(',');
    let encodedId = btoa(ids);
    setSelectedRowKeys([]);
    window.open(BASE_URL + "/v1/generate/qr/stok-bahan?url=" + urlFe + "&ids=" + encodedId);
  };

  const showQr = (record) => {
    let baseUrlFe = window.location.href;
    setQrData(baseUrlFe + "/mobile/" + record.id);
    fillForm(record);

    setIsOpenModalQr(true);
  }

  const onClose = () => {
    resetForm();
    setQrData();
    setIsOpenModalQr(false);
  }

  const onPrint = () => {
    let baseUrlFe = window.location.href;
    let urlFe = baseUrlFe + "/mobile";
    let encodedId = btoa(selectedData.id);
    window.open(BASE_URL + "/v1/generate/qr/stok-bahan?url=" + urlFe + "&ids=" + encodedId);
  }

  const add = async () => {
    const response = await dispatch(stokBahanApi.init());
    if (response.status === 200) {
      fillForm(response.data);
      setSelectedDataRoll({
        ...selectedDataRoll,
        id: response.data.id,
      });
      setIsOpenModal(true);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const onCancel = () => {
    resetForm();
    setRollMode("add");
    setFilterDropdownMasterBahan([
      {
        field: "jenis",
        value: ""
      },
      {
        field: "ketebalan",
        value: ""
      },
      {
        field: "gramasi",
        value: ""
      },
      {
        field: "warna",
        value: ""
      },
      {
        field: "setting",
        value: ""
      }
    ]);
    setIsOpenModal(false);
    setSelectedDataRoll({
      id: null,
      beratRoll: null,
    })
    setDataRoll([]);
    fetchData();
  };

  const onFinish = async (values) => {
    values = {
      ...values,
      id: selectedData.id,
      settingBahan: values.settingBahan.split(" ")[0],
      jenisSettingBahan: values.settingBahan.split(" ")[1],
      ketebalanBahan: values.ketebalanBahan.replace("s", ""),
    };
    setIsOpenModal(false);
    const response = await dispatch(stokBahanApi.postStok(values));
    if (response.status === 200) {
      let message = selectedData.createdAt !== null ? 'Success to edit stok bahan!' : 'Success to add new stok bahan!';
      openNotification('Success', message, 'success');
      resetForm();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      setFilterDropdownMasterBahan([
        {
          field: "jenis",
          value: ""
        },
        {
          field: "ketebalan",
          value: ""
        },
        {
          field: "gramasi",
          value: ""
        },
        {
          field: "warna",
          value: ""
        },
        {
          field: "setting",
          value: ""
        }
      ]);
      setDataRoll([]);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onEdit = (record) => {
    fillForm(record);
    setFilterDropdownMasterBahan([
      {
        field: "jenis",
        value: record.jenisBahan
      },
      {
        field: "ketebalan",
        value: record.ketebalanBahan
      },
      {
        field: "gramasi",
        value: record.gramasi
      },
      {
        field: "warna",
        value: record.warnaBahan
      },
      {
        field: "setting",
        value: record.settingBahan + " " + record.jenisSettingBahan
      }
    ]);
    setIsOpenModal(true);
    setDataRoll([{
      id: record.id,
      beratRoll: record.totalBeratRoll,
    }]);
    setSelectedDataRoll({
      id: record.id,
      beratRoll: null,
    });
  }

  const onEditRoll = (record) => {
    setRollMode("edit");
    setSelectedDataRoll(record);
  }

  const onChangeDropdown = (tipe, value) => {
    let list = filterDropdownMasterBahan;
    if (tipe === "jenis-bahan") {
      list = list.map(obj => {
        if (obj.field === "jenis") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "ketebalan-bahan") {
      list = list.map(obj => {
        if (obj.field === "ketebalan") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "gramasi") {
      list = list.map(obj => {
        if (obj.field === "gramasi") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "warna-bahan") {
      list = list.map(obj => {
        if (obj.field === "warna") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "setting-bahan") {
      list = list.map(obj => {
        if (obj.field === "setting") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } 

    setFilterDropdownMasterBahan(list);
  }

  const handleChangeRollValue = (e) => {
    setSelectedDataRoll({
      ...selectedDataRoll,
      beratRoll: e,
    });
  };

  const addRoll = async () => {
    setLoadingRoll(true);
    if (selectedDataRoll.id === null) {
      setSelectedDataRoll({
        ...selectedDataRoll,
        id: selectedData.id,
      });
    }
    const response = rollMode === "add" ? await dispatch(stokBahanApi.addBeratRoll(selectedDataRoll)) : await dispatch(stokBahanApi.updateBeratRoll(selectedDataRoll));
    if (response.status === 200) {
      setRollMode("add");
      setSelectedDataRoll({
        ...selectedDataRoll,
        beratRoll: null,
      });
      fetchCalculation();
      openNotification('Success', response.data, 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingRoll(false);
  }

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDropdown("jenis-bahan");
    fetchDropdown("ketebalan-bahan");
    fetchDropdown("gramasi");
    fetchDropdown("warna-bahan");
    fetchDropdown("setting-bahan");
  }, [
    filterDropdownMasterBahan
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button
            disabled={selectedRowKeys.length === 0}
            size="large" 
            type="primary"
            icon={<PrinterOutlined />}
            style={{ width: 100 }}
            iconPosition='start'
            onClick={print}
          >
            Print
          </Button>
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 180 }}
            iconPosition='start'
            onClick={add}
          >
            Add Stok Bahan
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title="Preview QR Code"
        open={isOpenModalQr}
        width={400}
        closable={false}
        footer={[]}
      >

        <Space id="qr" direction="vertical">
          <Flex gap="middle" vertical>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.jenisBahan + " - " + selectedData.warnaBahan}</label>
            
            <QRCode
              type='svg'
              style={{
                width: '352px',
                height: '352px',
              }}
              errorLevel="H"
              value={qrData}
            />

            <label style={{marginBottom:"16px", fontWeight:'bold', textAlign:'center'}}>Dicetak Oleh : CV. Ian Putra Reason</label>
          </Flex>
        </Space>

        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onClose}> 
            Close
          </Button>
          <Button type="primary" size="large" onClick={onPrint}>
            Print
          </Button>
        </Space>
      </Modal>
      <Modal
        title={selectedData.createdAt === null ? "Add New Stok Bahan" : "Edit Stok Bahan"}
        open={isOpenModal}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="main-form"
          className="main-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          
          <Form.Item
            layout="vertical"
            label="Jenis Bahan"
            name="jenisBahan"
            style={{width: '652px'}}
            rules={[
              {
                required: true,
                message: 'Please select jenis bahan',
              },
            ]}
          >
            <Select 
              showSearch
              allowClear
              placeholder="Select jenis bahan"
              options={dropdownJenisBahan} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" 
              onChange={(value) => {
                onChangeDropdown("jenis-bahan", value);
              }}/>
          </Form.Item>

          <Space>
            <Form.Item
              layout="vertical"
              label="Ketebalan Bahan"
              name="ketebalanBahan"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select ketebalan bahan',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Select ketebalan bahan"
                options={dropdownKetebalanBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("ketebalan-bahan", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Gramasi"
              name="gramasi"
              style={{width: '211px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select gramasi',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Select gramasi"
                options={dropdownGramasiBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("gramasi", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Warna Bahan"
              name="warnaBahan"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select warna bahan',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Select warna bahan"
                options={dropdownWarnaBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("warna-bahan", value);
                }}
              />
            </Form.Item>
          </Space>

          <Form.Item
            layout="vertical"
            label="Setting Bahan"
            name="settingBahan"
            style={{width: '652px'}}
            rules={[
              {
                required: true,
                message: 'Please select setting bahan',
              },
            ]}
          >
            <Select 
              showSearch
              allowClear
              placeholder="Select setting bahan"
              options={dropdownSettingBahan} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" 
              onChange={(value) => {
                onChangeDropdown("setting-bahan", value);
              }}/>
          </Form.Item>

          <Flex gap="middle" vertical style={{marginTop: '24px', marginBottom: '24px'}}>
            <Table
              columns={tableColumnsRoll}
              rowKey={(record) => record.id}
              dataSource={dataRoll}
              loading={{
                spinning: loadingRoll,
                indicator: <Spinner className="spinner"/>
              }}
            />
          </Flex>

          <Space>
            <Form.Item
              layout="vertical"
              label={rollMode === "add" ? "Tambah berat dalam 1 Roll" : "Jumlah Berat"}
              style={{width: '200px'}}
            >
              <InputNumber size="large" 
                min={1} style={{width: '100%'}}
                onChange={handleChangeRollValue}
                value={selectedDataRoll.beratRoll}/>
            </Form.Item>
            <Button 
              type="primary" 
              disabled={selectedDataRoll.beratRoll === null || selectedDataRoll.beratRoll === undefined || selectedDataRoll.beratRoll === ""}
              size="large"
              style={{marginTop:'6px'}}
              onClick={addRoll}
            >
              {rollMode === "add" ? "Tambah berat" : "Edit berat"}
            </Button>
          </Space>

          { selectedData.createdAt && 
          <Flex gap="middle" vertical style={{marginTop: '24px'}}>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.jenisBahan + " - " + selectedData.warnaBahan}</label>
            <div style={{margin: 'auto'}}>
              <QRCode
                type='svg'
                style={{
                  width: '352px',
                  height: '352px',
                  textAlign: 'center'
                }}
                errorLevel="H"
                value={BASE_URL + "/stok-bahan-mobile/" + selectedData.id}
              />
            </div>

            <label style={{marginBottom:"16px", fontWeight:'bold', textAlign: 'center'}}>Dicetak Oleh : CV. Ian Putra Reason</label>
          </Flex>
          } 

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.createdAt !== null ? "Edit" : "Add"}
              </Button>
            </Form.Item>
          </Space>
        </Form>

      </Modal>
    </Context.Provider>
  )
}

const StokBahan = () => {
  return (
    <MasterLayout
      pageName="Bahan"
      activeMenu="stok-bahan" 
      activeParent="stok"
      content={Content()}
    />
  )
}

export default StokBahan;