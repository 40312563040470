import './HargaModal.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  InputNumber,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as hargaModalApi from '../../stores/business/HargaModalApi';
import * as pakaianApi from '../../stores/business/PakaianApi';
import * as bahanApi from '../../stores/business/BahanApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [disabledButtonAksesoris, setDisabledButtonAksesoris] = useState(true);
  const [disabledButtonBahan, setDisabledButtonBahan] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSize, setLoadingSize] = useState(false);
  const [loadingAksesoris, setLoadingAksesoris] = useState(false);
  const [loadingBahan, setLoadingBahan] = useState(false);
  const [data, setData] = useState();
  const [dataSize, setDataSize] = useState();
  const [dataAksesoris, setDataAksesoris] = useState();
  const [dataBahan, setDataBahan] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('jenisPakaian');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState([]);
  const [namaPakaianOpt, setNamaPakaianOpt] = useState([]);
  const [jenisBahanOpt, setJenisBahanOpt] = useState([]);
  const [selectedData, setSelectedData] = useState({
    id: null,
    hargaModalSizeId: null,
    jenisPakaian: null,
    namaPakaian: null,
    jenisBahan: null,
    hargaTukangPotong: null,
    hargaSablon: null,
    hargaBordir: null,
    hargaCmt: null,
    hargaCmtCelana: null,
    hargaJualToko: null,
    hargaAksesoris: null,
    hargaLainnya: null,
  });
  const [lastUpdated, setLastUpdated] = useState('');
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });
  
  const [tableParamsSize, setTableParamsSize] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    sortField: "id",
    sortOrder: "ascend",
  });
  
  const [tableParamsAksesoris, setTableParamsAksesoris] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });
  
  const [tableParamsBahan, setTableParamsBahan] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Jenis Pakaian',
      dataIndex: 'jenisPakaian',
      key: 'jenisPakaian',
      sorter: true,
    },
    {
      title: 'Nama Pakaian',
      dataIndex: 'namaPakaian',
      key: 'namaPakaian',
      sorter: true,
    },
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
      render: (_, record) => (
        record.jenisBahan === null || record.jenisBahan === "" ? "-" : record.jenisBahan
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsSize = [
    {
      title: 'Ukuran',
      dataIndex: 'ukuran',
      key: 'ukuran',
    },
    {
      title: 'Harga Jual Toko',
      dataIndex: 'hargaJualToko',
      key: 'hargaJualToko',
      render: (_, record) => (
        record.hargaJualToko === null || record.hargaJualToko === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaJualToko).trim().split(",")[0]
      ),
    },
    {
      title: 'Harga Rata-rata Jual Toko',
      dataIndex: 'hargaRerataJualToko',
      key: 'hargaRerataJualToko',
      onCell: (record) => ({
        rowSpan: record.row % dataSize.length === 0 ? dataSize.length : 0,
      }),
      render: (_, record) => (
        record.hargaRerataJualToko === null || record.hargaRerataJualToko === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaRerataJualToko).trim().split(",")[0]
      ),
    },
    {
      title: 'Total Modal',
      dataIndex: 'totalModal',
      key: 'totalModal',
      render: (_, record) => (
        record.totalModal === null || record.totalModal === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalModal).trim().split(",")[0]
      ),
    },
    {
      title: 'Keuntungan',
      dataIndex: 'keuntungan',
      key: 'keuntungan',
      render: (_, record) => (
        record.keuntungan === null || record.keuntungan === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.keuntungan).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEditSize(record)}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsAksesoris = [
    {
      title: 'Keterangan',
      dataIndex: 'aksesoris',
      key: 'aksesoris',
      sorter: true,
    },
    {
      title: 'Harga',
      dataIndex: 'harga',
      key: 'harga',
      sorter: true,
      render: (_, record) => (
        record.harga === null || record.harga === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.harga).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirmAksesoris(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsBahan = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
    },
    {
      title: 'Berat',
      dataIndex: 'berat',
      key: 'berat',
      sorter: true,
      render: (_, record) => (
        record.berat + ' Kg'
      ),
    },
    {
      title: 'Harga',
      dataIndex: 'harga',
      key: 'harga',
      sorter: true,
      render: (_, record) => (
        record.harga === null || record.harga === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.harga).trim().split(",")[0]
      ),
    },
    {
      title: 'Total Harga',
      dataIndex: 'totalHarga',
      key: 'totalHarga',
      sorter: true,
      render: (_, record) => (
        record.totalHarga === null || record.totalHarga === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalHarga).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirmBahan(record);}}/>
        </Space>
      ),
    },
  ];

  const filterOptions = [
    {value: 'jenisPakaian', label: 'Jenis Pakaian'},
    {value: 'namaPakaian', label: 'Nama Pakaian'},
    {value: 'jenisBahan', label: 'Jenis Bahan'},
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableChangeSize = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsSize({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsSize.pagination?.pageSize) {
      setDataSize([]);
    }
  };

  const handleTableChangeAksesoris = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsAksesoris({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsAksesoris.pagination?.pageSize) {
      setDataAksesoris([]);
    }
  };

  const handleTableChangeBahan = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsBahan({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsBahan.pagination?.pageSize) {
      setDataBahan([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const onEdit = (record) => {
    record = {
      ...record,
      hargaModalSizeId: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaLainnya: null,
    }
    setSelectedData(record);
    form.setFieldsValue(record);
    form2.setFieldsValue(record);
    fetchNamaPakaian(record.jenisPakaian);
    setIsOpenModal(true)
  }

  const onEditSize = (record) => {
    record = {
      ...selectedData,
      hargaModalSizeId: record.id,
      hargaTukangPotong: record.hargaTukangPotong !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaTukangPotong).replace('Rp', '').trim().split(",")[0] : null,
      hargaSablon: record.hargaSablon !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaSablon).replace('Rp', '').trim().split(",")[0] : null,
      hargaBordir: record.hargaBordir !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaBordir).replace('Rp', '').trim().split(",")[0] : null,
      hargaCmt: record.hargaCmt !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaCmt).replace('Rp', '').trim().split(",")[0] : null,
      hargaCmtCelana: record.hargaCmtCelana !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaCmtCelana).replace('Rp', '').trim().split(",")[0] : null,
      totalModal: record.totalModal !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalModal).replace('Rp', '').trim().split(",")[0] : null,
      hargaJualToko: record.hargaJualToko !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaJualToko).replace('Rp', '').trim().split(",")[0] : null,
      hargaLainnya: record.hargaLainnya !== null ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaLainnya).replace('Rp', '').trim().split(",")[0] : null,
      hargaAksesoris: null,
      updatedAt: record.updatedAt,
      jenisBahan: null,
    }
    setDisabledButtonAksesoris(true);
    setSelectedData(record);
    form2.setFieldsValue(record);
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this harga?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const resetData = () => {
    setDisabledButtonAksesoris(true);
    form.resetFields();
    form2.resetFields();
    form.setFieldsValue({
      id: null,
      hargaModalSizeId: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaAksesoris: null,
    });
    form2.setFieldsValue({
      id: null,
      hargaModalSizeId: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaAksesoris: null,
    });
    setSelectedData({
      id: null,
      hargaModalSizeId: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaAksesoris: null,
    });
  }

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaModalApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataSize = async () => {
    if (selectedData.id === null) return;

    setLoadingSize(true);

    var sortField = tableParamsSize.sortField === undefined ? "id" : tableParamsSize.sortField;
    var sortOrder = tableParamsSize.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaModalApi.getPageSize(selectedData.id, {
      "page": 1,
      "size": 100,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": []
    }));
    if (response.status === 200) {
      let item = [];
      for (let i = 0; i < response.data.contents.length; i++) {
        let temp = response.data.contents[i];
        temp.row = i;
        item.push(temp);
      }
      setDataSize(item);
      setTableParamsSize({
        ...tableParamsSize,
        pagination: {
          ...tableParamsSize.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingSize(false);
  };

  const fetchDataAksesoris = async () => {
    if (selectedData.id === null) return;

    setLoadingAksesoris(true);

    var sortField = tableParamsAksesoris.sortField === undefined ? "id" : tableParamsAksesoris.sortField;
    var sortOrder = tableParamsAksesoris.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaModalApi.getPageAksesoris(selectedData.id, {
      "page": tableParamsAksesoris.pagination.current,
      "size": tableParamsAksesoris.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": []
    }));
    if (response.status === 200) {
      setDataAksesoris(response.data.contents);
      setTableParamsAksesoris({
        ...tableParamsAksesoris,
        pagination: {
          ...tableParamsAksesoris.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingAksesoris(false);
  };

  const fetchDataBahan = async () => {
    if (selectedData.id === null) return;

    setLoadingBahan(true);

    var sortField = tableParamsBahan.sortField === undefined ? "id" : tableParamsBahan.sortField;
    var sortOrder = tableParamsBahan.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaModalApi.getPageBahan(selectedData.id, {
      "page": tableParamsBahan.pagination.current,
      "size": tableParamsBahan.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": []
    }));
    if (response.status === 200) {
      setDataBahan(response.data.contents);
      setTableParamsBahan({
        ...tableParamsBahan,
        pagination: {
          ...tableParamsBahan.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingBahan(false);
  };

  const fetchJenisPakaian = async () => {
    const response = await dispatch(pakaianApi.getJenisPakaianList());
    if (response.status === 200) {
      setJenisPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchNamaPakaian = async (jenis) => {
    const response = await dispatch(pakaianApi.getNamaPakaianList(jenis));
    if (response.status === 200) {
      setNamaPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchJenisBahan = async () => {
    const response = await dispatch(bahanApi.getDropdown("jenis-bahan-modal",[]));
    if (response.status === 200) {
      setJenisBahanOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  function convertToIndonesianDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const fetchSizeLastUpdated = async () => {
    if (selectedData.hargaModalSizeId === null) return;
    const response = await dispatch(hargaModalApi.getSizeLastUpdated(selectedData.hargaModalSizeId));
    if (response.status === 200) {
      if (response.data !== null && response.data !== undefined) {
        setLastUpdated(convertToIndonesianDate(response.data.substring(0, 10)));
      }
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(hargaModalApi.deleteHarga(record.id));
    if (response.status === 200) {
      resetData();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete harga!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const showDeleteConfirmAksesoris = (record) => {
    confirm({
      title: 'Are you sure delete this aksesoris?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataAksesoris(record);
      },
    });
  }

  const deleteDataAksesoris = async (record) => {
    setLoadingSize(true);
    setLoadingAksesoris(true);
    const response = await dispatch(hargaModalApi.deleteAksesoris(record.id));
    if (response.status === 200) {
      if (tableParamsAksesoris.pagination.current > 1) {
        setTableParamsAksesoris({
          ...tableParamsAksesoris,
          pagination: {
            ...tableParamsAksesoris.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataAksesoris();
      }
      fetchDataSize();
      calculateTotalHargaBahanAndAksesoris();
      form2.setFieldValue('hargaAksesoris', null);
      fetchSizeLastUpdated();
      openNotification('Success', 'Success to delete aksesoris!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingSize(false);
    setLoadingAksesoris(false);
  };

  const addAksesoris = async () => {
    setLoadingSize(true);
    setLoadingAksesoris(true);
    let payload = {
      hargaModalId: selectedData.id,
      aksesoris: form2.getFieldValue('namaAksesoris'),
      harga: parseInt(form2.getFieldValue('hargaAksesoris').replace(/\D/g, '')),
    }
    const response = await dispatch(hargaModalApi.addAksesoris(payload));
    if (response.status === 200) {
      if (tableParamsAksesoris.pagination.current > 1) {
        setTableParamsAksesoris({
          ...tableParamsAksesoris,
          pagination: {
            ...tableParamsAksesoris.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataAksesoris();
      }
      form2.setFieldValue('namaAksesoris', null);
      form2.setFieldValue('hargaAksesoris', null);
      fetchDataSize();
      calculateTotalHargaBahanAndAksesoris();
      setDisabledButtonAksesoris(true);
      fetchSizeLastUpdated();
      openNotification('Success', 'Success to add aksesoris!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingSize(false);
    setLoadingAksesoris(false);
  }

  const addBahan = async () => {
    setLoadingSize(true);
    setLoadingAksesoris(true);
    let payload = {
      hargaModalId: selectedData.id,
      hargaModalSizeId: selectedData.hargaModalSizeId,
      jenisBahan: form2.getFieldValue('jenisBahan'),
      berat: form2.getFieldValue('beratBahan'),
    }
    const response = await dispatch(hargaModalApi.addBahan(payload));
    if (response.status === 200) {
      if (tableParamsBahan.pagination.current > 1) {
        setTableParamsBahan({
          ...tableParamsBahan,
          pagination: {
            ...tableParamsBahan.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataBahan();
      }
      form2.setFieldValue('jenisBahan', null);
      form2.setFieldValue('beratBahan', null);
      fetchData();
      fetchDataSize();
      calculateTotalHargaBahanAndAksesoris();
      setDisabledButtonBahan(true);
      fetchSizeLastUpdated();
      openNotification('Success', 'Success to add kain!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingSize(false);
    setLoadingAksesoris(false);
  }

  const showDeleteConfirmBahan = (record) => {
    confirm({
      title: 'Are you sure delete this kain?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataBahan(record);
      },
    });
  }

  const deleteDataBahan = async (record) => {
    setLoadingSize(true);
    setLoadingBahan(true);
    const response = await dispatch(hargaModalApi.deleteBahan(record.id));
    if (response.status === 200) {
      if (tableParamsBahan.pagination.current > 1) {
        setTableParamsBahan({
          ...tableParamsBahan,
          pagination: {
            ...tableParamsBahan.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataBahan();
      }
      form2.setFieldValue('jenisBahan', null);
      form2.setFieldValue('beratBahan', null);
      fetchData();
      fetchDataSize();
      calculateTotalHargaBahanAndAksesoris();
      fetchSizeLastUpdated();
      openNotification('Success', 'Success to delete kain!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingSize(false);
    setLoadingBahan(false);
  };

  const onCancel = () => {
    resetData();

    setIsOpenModal(false);
  };

  const calculateTotalHargaBahanAndAksesoris = async() => {
    const response = await dispatch(hargaModalApi.calculateTotalHargaBahanAndAksesoris(selectedData.id));
    if (response.status === 200) {
      let hargaTukangPotong = form2.getFieldValue('hargaTukangPotong') === null || form2.getFieldValue('hargaTukangPotong') === "" ? 0 : parseInt(form2.getFieldValue('hargaTukangPotong').replace('.', ''));
      let hargaSablon = form2.getFieldValue('hargaSablon') === null || form2.getFieldValue('hargaSablon') === "" ? 0 : parseInt(form2.getFieldValue('hargaSablon').replace('.', ''));
      let hargaBordir = form2.getFieldValue('hargaBordir') === null || form2.getFieldValue('hargaBordir') === "" ? 0 : parseInt(form2.getFieldValue('hargaBordir').replace('.', ''));
      let hargaCmt = form2.getFieldValue('hargaCmt') === null || form2.getFieldValue('hargaCmt') === "" ? 0 : parseInt(form2.getFieldValue('hargaCmt').replace('.', ''));
      let hargaCmtCelana = form2.getFieldValue('hargaCmtCelana') === null || form2.getFieldValue('hargaCmtCelana') === "" ? 0 : parseInt(form2.getFieldValue('hargaCmtCelana').replace('.', ''));
      let hargaLainnya = form2.getFieldValue('hargaLainnya') === null || form2.getFieldValue('hargaLainnya') === "" ? 0 : parseInt(form2.getFieldValue('hargaLainnya').replace('.', ''));
      let totalHarga = hargaTukangPotong + hargaSablon + hargaBordir + hargaCmt + hargaCmtCelana + hargaLainnya + response.data;

      setSelectedData({
        ...selectedData,
        totalModal: totalHarga,
        hargaTukangPotong: hargaTukangPotong,
        hargaSablon: hargaSablon,
        hargaBordir: hargaBordir,
        hargaCmt: hargaCmt,
        hargaCmtCelana: hargaCmtCelana,
        hargaLainnya: hargaLainnya,
        hargaJualToko: form2.getFieldValue('hargaJualToko') === null || form2.getFieldValue('hargaJualToko') === "" ? 0 : parseInt(form2.getFieldValue('hargaJualToko').replace('.', '')),
      });

      let formattedTotalHarga = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(totalHarga).replace('Rp', '').trim().split(",")[0];

      form2.setFieldValue('totalModal', formattedTotalHarga);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  }

  const onFinishForm1 = async (values) => {
    const response = await dispatch(hargaModalApi.add(values));
    if (response.status === 200) {
      let item = {
        ...response.data,
        hargaModalSizeId: null,
      };
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }

      setSelectedData(item);
      form2.setFieldsValue(item);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onFinishForm2 = async (values) => {
    console.log(values);
    values = {
      ...values,
      id: selectedData.hargaModalSizeId,
      hargaModalId: selectedData.id,
      hargaTukangPotong: values.hargaTukangPotong === null || values.hargaTukangPotong === "" ? null : parseInt(values.hargaTukangPotong.replace('.', '')),
      hargaSablon: values.hargaSablon === null || values.hargaSablon === "" ? null : parseInt(values.hargaSablon.replace('.', '')),
      hargaBordir: values.hargaBordir === null || values.hargaBordir === "" ? null : parseInt(values.hargaBordir.replace('.', '')),
      hargaCmt: values.hargaCmt === null || values.hargaCmt === "" ? null : parseInt(values.hargaCmt.replace('.', '')),
      hargaCmtCelana: values.hargaCmtCelana === null || values.hargaCmtCelana === "" ? null : parseInt(values.hargaCmtCelana.replace('.', '')),
      hargaLainnya: values.hargaLainnya === null || values.hargaLainnya === "" ? null : parseInt(values.hargaLainnya.replace('.', '')),
      hargaJualToko: values.hargaJualToko === null || values.hargaJualToko === "" ? null : parseInt(values.hargaJualToko.replace('.', '')),
    }
    const response = await dispatch(hargaModalApi.updateSize(values));
    if (response.status === 200) {
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataSize();
      }
      fetchData();
      fetchSizeLastUpdated();
      openNotification('Success', 'Success to update data!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataSize();
  }, [
    selectedData.id,
    tableParamsSize.pagination?.current,
    tableParamsSize.pagination?.pageSize,
    tableParamsSize?.sortOrder,
    tableParamsSize?.sortField,
  ]);

  useEffect(() => {
    fetchDataAksesoris();
  }, [
    selectedData.id,
    tableParamsAksesoris.pagination?.current,
    tableParamsAksesoris.pagination?.pageSize,
    tableParamsAksesoris?.sortOrder,
    tableParamsAksesoris?.sortField,
  ]);

  useEffect(() => {
    fetchDataBahan();
  }, [
    selectedData.id,
    tableParamsBahan.pagination?.current,
    tableParamsBahan.pagination?.pageSize,
    tableParamsBahan?.sortOrder,
    tableParamsBahan?.sortField,
  ]);

  useEffect(() => {
    fetchSizeLastUpdated();
  }, [
    selectedData.hargaModalSizeId,
  ]);

  useEffect(() => {
    fetchJenisPakaian();
    fetchJenisBahan();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Harga
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title={selectedData.id === null ? "Add Harga" : "Edit Harga"}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        {
          selectedData.id === null && 
          <Form
            form={form}
            initialValues={selectedData}
            name="harga-modal-form"
            className="harga-modal-form"
            layout="vertical"
            autoComplete="off"
            autoFocus={true}
            onFinish={onFinishForm1}
          >
            <Space>
              <Form.Item
                layout="vertical"
                label="Jenis Pakaian"
                name="jenisPakaian"
                style={{width: '350px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih jenis pakaian',
                  },
                ]}
              >
                <Select 
                  showSearch
                  placeholder="Pilih jenis pakaian"
                  options={jenisPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  onChange={(value) => {
                    form.setFieldsValue({
                      namaPakaian: null,
                    });
                    setSelectedData({
                      ...selectedData,
                      jenisPakaian: value,
                      namaPakaian: null,
                    });
                    fetchNamaPakaian(value);
                  }}
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Nama Pakaian"
                name="namaPakaian"
                style={{width: '494px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih nama pakaian',
                  },
                ]}
              >
                <Select 
                  showSearch
                  disabled={selectedData.jenisPakaian === ""}
                  placeholder="Pilih nama pakaian"
                  options={namaPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  onChange={(value) => {
                    setSelectedData({
                      ...selectedData,
                      namaPakaian: value,
                    });
                  }}
                  size="large" />
              </Form.Item>
            </Space>
            
            <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  Save to Continue
                </Button>
              </Form.Item>
            </Space>
          </Form>
        }
        {
          selectedData.id !== null && 
          <Form
            form={form2}
            initialValues={selectedData}
            name="harga-modal-form"
            className="harga-modal-form"
            layout="vertical"
            autoComplete="off"
            autoFocus={true}
            onFinish={onFinishForm2}
          >
            <Space>
              <Form.Item
                layout="vertical"
                label="Jenis Pakaian"
                name="jenisPakaian"
                style={{width: '350px'}}
              >
                <Select 
                  showSearch
                  disabled
                  placeholder="Pilih jenis pakaian"
                  options={jenisPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Nama Pakaian"
                name="namaPakaian"
                style={{width: '494px'}}
              >
                <Select 
                  showSearch
                  disabled
                  placeholder="Pilih nama pakaian"
                  options={namaPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  size="large" />
              </Form.Item>
            </Space>

            <span>
              <label style={{fontSize:'24px', fontWeight:'bold', marginTop:'16px'}}>Harga Toko </label>
              <label style={{fontSize:'16px', marginTop:'16px'}}>(Silakan pilih salah satu ukuran untuk edit data)</label>
            </span>

            <Flex gap="middle" vertical style={{marginTop: '16px'}}>
              <Table
                bordered
                columns={tableColumnsSize}
                rowKey={(record) => record.id}
                dataSource={dataSize}
                pagination={false}
                loading={{
                  spinning: loadingSize,
                  indicator: <Spinner className="spinner"/>
                }}
                onChange={handleTableChangeSize}
              />
            </Flex>

            {
              selectedData.hargaModalSizeId !== null && 
              <>
              <Flex>
                <label style={{fontSize:'24px', fontWeight:'bold', marginTop:'16px'}}>Kain</label>
              </Flex>

              <Flex gap="middle" vertical style={{marginTop: '8px'}}>
                <Table
                  columns={tableColumnsBahan}
                  rowKey={(record) => record.id}
                  dataSource={dataBahan}
                  pagination={tableParamsBahan.pagination}
                  loading={{
                    spinning: loadingBahan,
                    indicator: <Spinner className="spinner"/>
                  }}
                  onChange={handleTableChangeBahan}
                />
              </Flex>

              <Space style={{marginTop: '16px'}}>
                <Form.Item
                  layout="vertical"
                  label="Jenis Bahan"
                  name="jenisBahan"
                  style={{width: '278px'}}
                >
                  <Select 
                    showSearch
                    placeholder="Pilih jenis bahan"
                    options={jenisBahanOpt} 
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    optionLabelProp="label"
                    size="large" onChange={(value) => {
                      if (value !== null && value !== undefined && form2.getFieldValue('beratBahan') !== null && form2.getFieldValue('beratBahan') !== undefined && form2.getFieldValue('beratBahan') > 0) {
                        setDisabledButtonBahan(false);
                      } else {
                        setDisabledButtonBahan(true);
                      }
                    }}/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Berat Bahan (Maks. 1000)"
                  name="beratBahan"
                  style={{width: '278px'}}
                >
                  <InputNumber size='large' addonAfter="Kg" min={0} max={1000} style={{width: '100%'}} 
                    placeholder="Berat kain" onChange={(value) => {
                      if (value !== null && value !== undefined && value > 0 && form2.getFieldValue('jenisBahan') !== null && form2.getFieldValue('jenisBahan') !== undefined) {
                        setDisabledButtonBahan(false);
                      } else {
                        setDisabledButtonBahan(true);
                      }
                    }}/>
                </Form.Item>
                <Button style={{marginTop: '7px'}} htmlType="button" type='primary' size="large" disabled={disabledButtonBahan} onClick={addBahan}> 
                  Add Kain
                </Button>
              </Space>
              <Space>
                <Form.Item
                  layout="vertical"
                  label="Tukang Potong"
                  name="hargaTukangPotong"
                  style={{width: '278px'}}
                  rules={[
                    {
                      required: true,
                      message: 'Please input harga',
                    },
                  ]}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga tukang potong" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaTukangPotong', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }} />
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Sablon"
                  name="hargaSablon"
                  style={{width: '278px'}}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga sablon" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaSablon', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Bordir"
                  name="hargaBordir"
                  style={{width: '279px'}}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga bordir" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaBordir', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
              </Space>
              <Space>
                <Form.Item
                  layout="vertical"
                  label="CMT Baju"
                  name="hargaCmt"
                  style={{width: '278px'}}
                  rules={[
                    {
                      required: true,
                      message: 'Please input harga',
                    },
                  ]}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga CMT" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaCmt', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="CMT Celana"
                  name="hargaCmtCelana"
                  style={{width: '278px'}}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga CMT Celana" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaCmtCelana', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Lainnya"
                  name="hargaLainnya"
                  style={{width: '279px'}}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
                    placeholder="Harga Lainnya" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaLainnya', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
              </Space>

              <Flex>
                <label style={{fontSize:'24px', fontWeight:'bold', marginTop:'16px'}}>Aksesoris</label>
              </Flex>

              <Flex gap="middle" vertical style={{marginTop: '8px'}}>
                <Table
                  columns={tableColumnsAksesoris}
                  rowKey={(record) => record.id}
                  dataSource={dataAksesoris}
                  pagination={tableParamsAksesoris.pagination}
                  loading={{
                    spinning: loadingAksesoris,
                    indicator: <Spinner className="spinner"/>
                  }}
                  onChange={handleTableChangeAksesoris}
                />
              </Flex>
              <Space>
                <Form.Item
                  layout="vertical"
                  label="Nama Aksesoris"
                  name="namaAksesoris"
                  style={{width: '278px', marginTop: '16px'}}
                >
                  <Input size='large' maxLength={20} style={{width: '100%'}} 
                    placeholder="Nama Aksesoris" onChange={(value) => {
                      let hargaAksesoris = form2.getFieldValue('hargaAksesoris') !== null ? parseInt(form2.getFieldValue('hargaAksesoris').replace(/\D/g, '')) : null;
                      if (value !== null && value !== undefined && hargaAksesoris !== null && hargaAksesoris !== undefined && hargaAksesoris > 0) {
                        setDisabledButtonAksesoris(false);
                      } else {
                        setDisabledButtonAksesoris(true);
                      }
                    }}/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Harga Aksesoris (Maks. 1 juta)"
                  name="hargaAksesoris"
                  style={{width: '278px', marginTop: '16px'}}
                >
                  <Input size='large' addonBefore="Rp" min={1} max={1000000} style={{width: '100%'}} 
                    placeholder="Harga aksesoris" onChange={(e) => {
                      let namaAksesoris = form2.getFieldValue('namaAksesoris');

                      let value = e.target.value !== null ? e.target.value.replace(/\D/g, '') : e.target.value;
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaAksesoris', formattedNumber);

                      if (value !== null && value !== undefined && value !== "" && parseInt(value) > 0 && namaAksesoris !== null && namaAksesoris !== undefined) {
                        setDisabledButtonAksesoris(false);
                        if (value > 1000000) {
                          form2.setFieldValue('hargaAksesoris', '1.000.000');
                        }
                      } else {
                        setDisabledButtonAksesoris(true);
                      }
                    }}/>
                </Form.Item>
                <Button style={{marginTop: '23px'}} htmlType="button" type='primary' size="large" disabled={disabledButtonAksesoris} onClick={addAksesoris}> 
                  Add Aksesoris
                </Button>
              </Space>
              
              <Space style={{marginTop: '16px'}}>
                <Form.Item
                  layout="vertical"
                  label="Total Modal"
                  name="totalModal"
                  style={{width: '278px'}}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} disabled
                    placeholder="Total modal"/>
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Harga Jual Toko"
                  name="hargaJualToko"
                  style={{width: '278px'}}
                  rules={[
                    {
                      required: true,
                      message: 'Please input harga',
                    },
                  ]}
                >
                  <Input size='large' addonBefore="Rp" min={1} style={{width: '100%'}} 
                    placeholder="Harga jual toko" onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form2.setFieldValue('hargaJualToko', formattedNumber);
                      calculateTotalHargaBahanAndAksesoris()
                    }}/>
                </Form.Item>
              </Space>
              </>
            }
            <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  Edit
                </Button>
              </Form.Item>
              {
                selectedData.hargaModalSizeId !== null && 
                <span className='last-updated-wrapper'>Last Updated : <label className='last-updated'>{lastUpdated === '' || lastUpdated === null || lastUpdated === undefined ? '-' : lastUpdated}</label></span>
              }
            </Space>
          </Form>
        }
      </Modal>
    </Context.Provider>
  )
}

const HargaModal = () => {
  return (
    <MasterLayout
      pageName="Harga Modal"
      activeMenu="harga-modal" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default HargaModal;