import { React, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { IsTokenValid } from './libs/Auth';

import Login from './pages/login/Login';
import './App.scss';
import Dashboard from './pages/dashboard/Dashboard';
import Role from './pages/role/Role';
import User from './pages/user/User';
import Customer from './pages/customer/Customer';
import Bahan from './pages/bahan/Bahan';
import Merek from './pages/merek/Merek';
import Pakaian from './pages/pakaian/Pakaian';
import Gambar from './pages/gambar/Gambar';
import StokBahan from './pages/stok_bahan/StokBahan';
import Aksesoris from './pages/aksesoris/Aksesoris';
import Variasi from './pages/variasi/Variasi';
import PO from './pages/po/PO';
import POAdmin from './pages/po/POAdmin';
import HargaModal from './pages/harga_modal/HargaModal';

const App = () => {
  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const loginToken = IsTokenValid();
    if (!loginToken && !loc.pathname.includes('login')) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/master/role" element={<Role />} />
        <Route path="/master/user" element={<User />} />
        <Route path="/master/customer" element={<Customer />} />
        <Route path="/master/bahan" element={<Bahan />} />
        <Route path="/master/merek" element={<Merek />} />
        <Route path="/master/pakaian" element={<Pakaian />} />
        <Route path="/master/gambar" element={<Gambar />} />
        <Route path="/stok/bahan" element={<StokBahan />} />
        <Route path="/master/aksesoris" element={<Aksesoris />} />
        <Route path="/master/variasi" element={<Variasi />} />
        <Route path="/po/produksi" element={<PO />} />
        <Route path="/po/administrasi" element={<POAdmin />} />
        <Route path="/master/harga-modal" element={<HargaModal />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
