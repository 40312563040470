import { CallRestApiSecure } from '../../libs/Api';

export const initPakaian = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/pakaian', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getPakaianPage = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/pakaian/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getPakaianSizePage = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/pakaian/size/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const createOrUpdatePakaian = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/pakaian', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const createOrUpdatePakaianSize = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/pakaian/size', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const deletePakaian = (id) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/pakaian/delete/'+ id, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const deletePakaianSize = (id) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/pakaian/size/delete/'+ id, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getJenisPakaianList = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/pakaian/list/jenis', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getNamaPakaianList = (jenis) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/pakaian/list/nama/' + jenis, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};