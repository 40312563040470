import React, { useEffect, useState } from 'react';
import './PO.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import dayjs from 'dayjs';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  DatePicker,
  Divider,
  Checkbox,
  Card,
  Image,
  Radio,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  PictureOutlined,
  PrinterOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import * as poApi from '../../stores/business/PoApi';
import * as customerApi from '../../stores/business/CustomerApi';
import * as merekApi from '../../stores/business/MerekApi';
import * as pakaianApi from '../../stores/business/PakaianApi';
import * as stokBahanApi from '../../stores/business/StokBahanApi';
import * as masterDataApi from '../../stores/business/MasterDataApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import noImage from '../../assets/no-image.png';

const Context = React.createContext({
  name: 'Default',
});

const { Meta } = Card;

const { confirm } = Modal;

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [formStok] = Form.useForm();
  const [formGambar] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [loadingStok, setLoadingStok] = useState(false);
  const [loadingGambar, setLoadingGambar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalStok, setIsOpenModalStok] = useState(false);
  const [isOpenModalGambar, setIsOpenModalGambar] = useState(false);
  const [isOpenModalPilihGambar, setIsOpenModalPilihGambar] = useState(false);
  const [isOpenModalGantiGambar, setIsOpenModalGantiGambar] = useState(false);
  const [mode, setMode] = useState();
  const [data, setData] = useState();
  const [dataStok, setDataStok] = useState();
  const [dataGambar, setDataGambar] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('noPo');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [tokoOpt, setTokoOpt] = useState([]);
  const [merekOpt, setMerekOpt] = useState([]);
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState([]);
  const [namaPakaianOpt, setNamaPakaianOpt] = useState([]);
  const [variasiOpt, setVariasiOpt] = useState([]);
  const [aksesorisOpt, setAksesorisOpt] = useState([]);
  const [selectedGambarOpt, setSelectedGambarOpt] = useState([]);
  const [gambarOpt, setGambarOpt] = useState([]);
  const [gambarSearchValue, setGambarSearchValue] = useState("");
  const [previewImages, setPreviewImages] = useState({
    items: [],
    visible: false,
  });
  const [selectedData, setSelectedData] = useState({
    id: null,
    noPo: "",
    namaToko: "",
    merek: "",
    tanggalPotong: "",
    tanggalPotongStr: "",
    tanggalPotongAnt: null,
    jenisPakaian: "",
    namaPakaian: "",
    statusProduksi: "",
    statusAdministrasi: "",
    statusPo: "",
    createdAt: null,
    isTukangPotongDone: false,
    isDiserahkanTukangPotong: false,
    namaTukangPotong: "",
    tglDiserahkanTukangPotong: "",
    tglDiserahkanTukangPotongAnt: null,
    isTukangSablonDone: false,
    isDiperlukanTukangSablon: false,
    isDiserahkanTukangSablon: false,
    namaTukangSablon: "",
    tglDiserahkanTukangSablon: "",
    tglDiserahkanTukangSablonAnt: null,
    isTukangBordirDone: false,
    isDiperlukanTukangBordir: false,
    isDiserahkanTukangBordir: false,
    namaTukangBordir: "",
    tglDiserahkanTukangBordir: "",
    tglDiserahkanTukangBordirAnt: null,
    isTukangCmtDone: false,
    isDiserahkanTukangCmt: false,
    namaTukangCmt: "",
    tglDiserahkanTukangCmt: "",
    tglDiserahkanTukangCmtAnt: null,
    isTukangCmtCelanaDone: false,
    isDiperlukanTukangCmtCelana: false,
    isDiserahkanTukangCmtCelana: false,
    namaTukangCmtCelana: "",
    tglDiserahkanTukangCmtCelana: "",
    tglDiserahkanTukangCmtCelanaAnt: null,
    isPicDone: false,
    isDiserahkanPic: false,
    namaPic: "",
    tglDiserahkanPic: "",
    tglDiserahkanPicAnt: null,
    totalBeratKain: null,
    images: [],
    totalLusin: null,
    totalPieceRusak: null,
    totalKeseluruhan: null,
  });
  const [selectedDataStok, setSelectedDataStok] = useState({
    id: null,
    poId: null,
    jenisBahan: null,
    warnaBahan: null,
    ketebalanBahan: null,
    settingBahan: null,
    jenisSettingBahan: null,
    beratKain: null,
  });
  const [selectedDataGambar, setSelectedDataGambar] = useState({
    id: null,
    poId: null,
    kodeGambar: null,
    urlGambar: null,
    namaGambar: null,
    variasi: [],
    aksesoris: [],
    jumlahLusin: null,
    sizes: [
      {
        id: 0,
        poId: 0,
        poGambarId: 0,
        ukuran: "",
        jumlah: 0,
        kerusakanTukangPotong: 0,
        kerusakanSablon: 0,
        kerusakanBordir: 0,
        kerusakanCmt: 0,
        kerusakanCmtCelana: 0
      }
    ]
  });
  const [filterDropdownMasterBahan, setFilterDropdownMasterBahan] = useState([
    {
      field: "jenis",
      value: ""
    },
    {
      field: "ketebalan",
      value: ""
    },
    {
      field: "gramasi",
      value: ""
    },
    {
      field: "warna",
      value: ""
    },
    {
      field: "setting",
      value: ""
    }
  ]);
  const [dropdownJenisBahan, setDropdownJenisBahan] = useState();
  const [dropdownKetebalanBahan, setDropdownKetebalanBahan] = useState();
  const [dropdownGramasiBahan, setDropdownGramasiBahan] = useState();
  const [dropdownWarnaBahan, setDropdownWarnaBahan] = useState();
  const [dropdownSettingBahan, setDropdownSettingBahan] = useState();

  const filterOptions = [
    {value: 'noPo', label: 'No. Purchase Order (PO)'},
    {value: 'tanggalPotongStr', label: 'Tanggal Potong'},
    {value: 'statusProduksi', label: 'Status Produksi'},
    {value: 'statusAdministrasi', label: 'Status Administrasi'},
    {value: 'statusPo', label: 'Status Purchase Order (PO)'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'No. Purchase Order (PO)',
      dataIndex: 'noPo',
      key: 'noPo',
      sorter: true,
    },
    {
      title: 'Tanggal Potong',
      dataIndex: 'tanggalPotongStr',
      key: 'tanggalPotongStr',
      sorter: true,
    },
    {
      title: 'Status Produksi',
      dataIndex: 'statusProduksi',
      key: 'statusProduksi',
      sorter: true,
      render: (_, record) => (
        record.statusProduksi === null ? "-" : record.statusProduksi
      ),
    },
    {
      title: 'Status Administrasi',
      dataIndex: 'statusAdministrasi',
      key: 'statusAdministrasi',
      sorter: true,
      render: (_, record) => (
        record.statusAdministrasi === null ? "-" : record.statusAdministrasi
      ),
    },
    {
      title: 'Status Purchase Order (PO)',
      dataIndex: 'statusPo',
      key: 'statusPo',
      sorter: true,
      render: (_, record) => (
        record.statusPo === null ? "-" : record.statusPo
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {record.statusProduksi !== "Draft" && <Button shape="circle" icon={<PrinterOutlined />} onClick={() => {print(record);}}/>}
          {record.images?.length > 0 && <Button shape="circle" icon={<PictureOutlined />} onClick={() => {openPreviewImages(record);}}/>}
          {record.statusProduksi !== "Done" && <Button shape="circle" icon={<EditOutlined />} onClick={() => {editPo(record);}}/>}
          {record.statusProduksi === "Done" && <Button shape="circle" icon={<EyeOutlined />} onClick={() => {viewPo(record);}}/>}
          {record.statusProduksi === "Draft" && <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>}
        </Space>
      ),
    },
  ];
  
  const [tableParamsStok, setTableParamsStok] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumnsStok = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
    },
    {
      title: 'Warna Bahan',
      dataIndex: 'warnaBahan',
      key: 'warnaBahan',
      sorter: true,
    },
    {
      title: 'Ketebalan Bahan',
      dataIndex: 'ketebalanBahan',
      key: 'ketebalanBahan',
      sorter: true,
      render: (_, record) => (
        record.ketebalanBahan + "s"
      ),
    },
    {
      title: 'Setting Bahan',
      dataIndex: 'settingBahan',
      key: 'settingBahan',
      sorter: true,
    },
    {
      title: 'Jenis Setting Bahan',
      dataIndex: 'jenisSettingBahan',
      key: 'jenisSettingBahan',
      sorter: true,
    },
    {
      title: 'Kg',
      dataIndex: 'beratKain',
      key: 'beratKain',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={selectedData.statusProduksi === "Draft" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onEditStok(record)}}/>
          {selectedData.statusProduksi === "Draft" && <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteStokConfirm(record);}}/>}
        </Space>
      ),
    },
  ];
  
  const [tableParamsGambar, setTableParamsGambar] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumnsGambar = [
    {
      title: 'Kode Gambar',
      dataIndex: 'kodeGambar',
      key: 'kodeGambar',
      sorter: true,
      render: (_, record) => (
        record.kodeGambar === null ? "NO IMAGE" : record.kodeGambar
      ),
    },
    {
      title: 'Nama Gambar',
      dataIndex: 'namaGambar',
      key: 'namaGambar',
      sorter: true,
      render: (_, record) => (
        record.namaGambar === null ? "NO IMAGE" : record.namaGambar
      ),
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'jumlahLusin',
      key: 'jumlahLusin',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {selectedData.statusProduksi !== "Done" && 
            <Button shape="circle" icon={<UploadOutlined />} onClick={() => {onGantiGambar(record);}}/>}
          <Button shape="circle" icon={selectedData.statusProduksi === "Draft" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onEditGambar(record)}}/>
          {selectedData.statusProduksi === "Draft" && <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteGambarConfirm(record);}}/>}
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableChangeStok = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsStok({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsStok.pagination?.pageSize) {
      setDataStok([]);
    }
  };

  const handleTableChangeGambar = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsGambar({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsGambar.pagination?.pageSize) {
      setDataGambar([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageProduction({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataStok = async () => {
    setLoadingStok(true);
    if (selectedData.id == null) {
      setLoadingStok(false);
      return;
    }

    var sortField = tableParamsStok.sortField === undefined ? "id" : tableParamsStok.sortField;
    var sortOrder = tableParamsStok.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageStok({
      "page": tableParamsStok.pagination.current,
      "size": tableParamsStok.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: "poId",
          value: selectedData.id
        }
      ]
    }));
    if (response.status === 200) {
      setDataStok(response.data.contents);
      setTableParamsStok({
        ...tableParamsStok,
        pagination: {
          ...tableParamsStok.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingStok(false);
  };

  const fetchStokCalculation = async () => {
    if (selectedData.id == null) {
      return;
    }

    const response = await dispatch(poApi.getStokCalculation(selectedData.id));
    if (response.status === 200) {
      form.setFieldValue('totalBeratKain', response.data);
      setSelectedData({
        ...selectedData,
        totalBeratKain: response.data,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchTotalLusin = async () => {
    if (selectedData.id == null) {
      return;
    }

    const response = await dispatch(poApi.getTotalLusin(selectedData.id));
    if (response.status === 200) {
      form.setFieldValue('totalLusin', response.data);
      setSelectedData({
        ...selectedData,
        totalLusin: response.data,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchToko = async () => {
    const response = await dispatch(customerApi.getTokoList());
    if (response.status === 200) {
      setTokoOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchMerek = async () => {
    const response = await dispatch(merekApi.getMerekList());
    if (response.status === 200) {
      setMerekOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchJenisPakaian = async () => {
    const response = await dispatch(pakaianApi.getJenisPakaianList());
    if (response.status === 200) {
      setJenisPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchNamaPakaian = async (jenis) => {
    const response = await dispatch(pakaianApi.getNamaPakaianList(jenis));
    if (response.status === 200) {
      setNamaPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchDropdown = async (tipe) => {
    let list = filterDropdownMasterBahan;
    list = list.map(obj => {
      if (obj.field === "ketebalan") {
        obj.value = obj.value ? obj.value.replace("s", "") : "";
      }
      return obj;
    });
    const response = await dispatch(stokBahanApi.getDropdownStokBahan(tipe, list));
    if (response.status === 200) {
      if (tipe === "jenis-bahan") {
        setDropdownJenisBahan(response.data);
      } else if (tipe === "ketebalan-bahan") {
        setDropdownKetebalanBahan(response.data);
      } else if (tipe === "gramasi") {
        setDropdownGramasiBahan(response.data);
      } else if (tipe === "warna-bahan") {
        setDropdownWarnaBahan(response.data);
      } else if (tipe === "setting-bahan") {
        setDropdownSettingBahan(response.data);
      } 
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchAksesoris = async () => {
    const response = await dispatch(masterDataApi.getAksesoris());
    if (response.status === 200) {
      setAksesorisOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchVariasi = async () => {
    const response = await dispatch(masterDataApi.getVariasi());
    if (response.status === 200) {
      setVariasiOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchGambar = async () => {
    const response = await dispatch(masterDataApi.getGambar(gambarSearchValue));
    if (response.status === 200) {
      response.data.forEach(obj => {
        obj.urlGambar = BASE_URL + obj.urlGambar;
      });
      setGambarOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedData({
      id: null,
      noPo: "",
      namaToko: "",
      merek: "",
      tanggalPotong: "",
      tanggalPotongStr: "",
      tanggalPotongAnt: null,
      jenisPakaian: "",
      namaPakaian: "",
      statusProduksi: "",
      statusAdministrasi: "",
      statusPo: "",
      createdAt: null,
      isTukangPotongDone: false,
      isDiserahkanTukangPotong: false,
      namaTukangPotong: "",
      tglDiserahkanTukangPotong: "",
      tglDiserahkanTukangPotongAnt: null,
      isTukangSablonDone: false,
      isDiperlukanTukangSablon: false,
      isDiserahkanTukangSablon: false,
      namaTukangSablon: "",
      tglDiserahkanTukangSablon: "",
      tglDiserahkanTukangSablonAnt: null,
      isTukangBordirDone: false,
      isDiperlukanTukangBordir: false,
      isDiserahkanTukangBordir: false,
      namaTukangBordir: "",
      tglDiserahkanTukangBordir: "",
      tglDiserahkanTukangBordirAnt: null,
      isTukangCmtDone: false,
      isDiserahkanTukangCmt: false,
      namaTukangCmt: "",
      tglDiserahkanTukangCmt: "",
      tglDiserahkanTukangCmtAnt: null,
      isTukangCmtCelanaDone: false,
      isDiperlukanTukangCmtCelana: false,
      isDiserahkanTukangCmtCelana: false,
      namaTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelanaAnt: null,
      isPicDone: false,
      isDiserahkanPic: false,
      namaPic: "",
      tglDiserahkanPic: "",
      tglDiserahkanPicAnt: null,
      totalBeratKain: null,
      images: [],
      totalLusin: null,
      totalPieceRusak: null,
      totalKeseluruhan: null,
    });
    form.setFieldsValue({
      id: null,
      noPo: "",
      namaToko: "",
      merek: "",
      tanggalPotong: "",
      tanggalPotongStr: "",
      tanggalPotongAnt: null,
      jenisPakaian: "",
      namaPakaian: "",
      statusProduksi: "",
      statusAdministrasi: "",
      statusPo: "",
      createdAt: null,
      isTukangPotongDone: false,
      isDiserahkanTukangPotong: false,
      namaTukangPotong: "",
      tglDiserahkanTukangPotong: "",
      tglDiserahkanTukangPotongAnt: null,
      isTukangSablonDone: false,
      isDiperlukanTukangSablon: false,
      isDiserahkanTukangSablon: false,
      namaTukangSablon: "",
      tglDiserahkanTukangSablon: "",
      tglDiserahkanTukangSablonAnt: null,
      isTukangBordirDone: false,
      isDiperlukanTukangBordir: false,
      isDiserahkanTukangBordir: false,
      namaTukangBordir: "",
      tglDiserahkanTukangBordir: "",
      tglDiserahkanTukangBordirAnt: null,
      isTukangCmtDone: false,
      isDiserahkanTukangCmt: false,
      namaTukangCmt: "",
      tglDiserahkanTukangCmt: "",
      tglDiserahkanTukangCmtAnt: null,
      isTukangCmtCelanaDone: false,
      isDiperlukanTukangCmtCelana: false,
      isDiserahkanTukangCmtCelana: false,
      namaTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelanaAnt: null,
      isPicDone: false,
      isDiserahkanPic: false,
      namaPic: "",
      tglDiserahkanPic: "",
      tglDiserahkanPicAnt: null,
      totalBeratKain: null,
      images: [],
      totalLusin: null,
      totalPieceRusak: null,
      totalKeseluruhan: null,
    });
  }

  const resetFormGambar = () => {
    formGambar.resetFields();
    setSelectedDataGambar({
      id: null,
      poId: null,
      kodeGambar: null,
      urlGambar: null,
      namaGambar: null,
      variasi: [],
      aksesoris: [],
      jumlahLusin: null,
      sizes: [
        {
          id: 0,
          poId: 0,
          poGambarId: 0,
          ukuran: "",
          jumlah: 0,
          kerusakanTukangPotong: 0,
          kerusakanSablon: 0,
          kerusakanBordir: 0,
          kerusakanCmt: 0,
          kerusakanCmtCelana: 0
        }
      ]
    });
    formGambar.setFieldsValue({
      id: null,
      poId: null,
      kodeGambar: null,
      urlGambar: null,
      namaGambar: null,
      variasi: [],
      aksesoris: [],
      jumlahLusin: null,
      sizes: [
        {
          id: 0,
          poId: 0,
          poGambarId: 0,
          ukuran: "",
          jumlah: 0,
          kerusakanTukangPotong: 0,
          kerusakanSablon: 0,
          kerusakanBordir: 0,
          kerusakanCmt: 0,
          kerusakanCmtCelana: 0
        }
      ]
    });
  }

  const resetFormStok = () => {
    formStok.resetFields();
    setFilterDropdownMasterBahan([
      {
        field: "jenis",
        value: ""
      },
      {
        field: "ketebalan",
        value: ""
      },
      {
        field: "gramasi",
        value: ""
      },
      {
        field: "warna",
        value: ""
      },
      {
        field: "setting",
        value: ""
      }
    ]);
    setSelectedDataStok({
      id: null,
      poId: null,
      jenisBahan: null,
      warnaBahan: null,
      ketebalanBahan: null,
      settingBahan: null,
      jenisSettingBahan: null,
      beratKain: null,
    });
    formStok.setFieldsValue({
      id: null,
      poId: null,
      jenisBahan: null,
      warnaBahan: null,
      ketebalanBahan: null,
      settingBahan: null,
      jenisSettingBahan: null,
      beratKain: null,
    });
  }

  const editPo = (record) => {
    if (record.statusProduksi === "Draft") {
      setMode("Edit");
    } else {
      setMode("Paraf");
    }

    fetchNamaPakaian(record.jenisPakaian);

    record = {
      ...record,
      tanggalPotongAnt: dayjs(record.tanggalPotong),
      isTukangPotongDone: record.isDiserahkanTukangPotong,
      isTukangSablonDone: record.isDiserahkanTukangSablon,
      isTukangBordirDone: record.isDiserahkanTukangBordir,
      isTukangCmtDone: record.isDiserahkanTukangCmt,
      isTukangCmtCelanaDone: record.isDiserahkanTukangCmtCelana,
      isPicDone: record.isDiserahkanPic,
      tglDiserahkanTukangPotongAnt: record.tglDiserahkanTukangPotong ? dayjs(record.tglDiserahkanTukangPotong) : null,
      tglDiserahkanTukangBordirAnt: record.tglDiserahkanTukangBordir ? dayjs(record.tglDiserahkanTukangBordir) : null,
      tglDiserahkanTukangSablonAnt: record.tglDiserahkanTukangSablon ? dayjs(record.tglDiserahkanTukangSablon) : null,
      tglDiserahkanTukangCmtAnt: record.tglDiserahkanTukangCmt ? dayjs(record.tglDiserahkanTukangCmt) : null,
      tglDiserahkanTukangCmtCelanaAnt: record.tglDiserahkanTukangCmtCelana ? dayjs(record.tglDiserahkanTukangCmtCelana) : null,
      tglDiserahkanPicAnt: record.tglDiserahkanPic ? dayjs(record.tglDiserahkanPic) : null,
    }

    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true);
  };

  const viewPo = (record) => {
    setMode("View");

    fetchNamaPakaian(record.jenisPakaian);

    record = {
      ...record,
      tanggalPotongAnt: dayjs(record.tanggalPotong),
      isTukangPotongDone: record.isDiserahkanTukangPotong,
      isTukangSablonDone: record.isDiserahkanTukangSablon,
      isTukangBordirDone: record.isDiserahkanTukangBordir,
      isTukangCmtDone: record.isDiserahkanTukangCmt,
      isTukangCmtCelanaDone: record.isDiserahkanTukangCmtCelana,
      isPicDone: record.isDiserahkanPic,
      tglDiserahkanTukangPotongAnt: record.tglDiserahkanTukangPotong ? dayjs(record.tglDiserahkanTukangPotong) : null,
      tglDiserahkanTukangBordirAnt: record.tglDiserahkanTukangBordir ? dayjs(record.tglDiserahkanTukangBordir) : null,
      tglDiserahkanTukangSablonAnt: record.tglDiserahkanTukangSablon ? dayjs(record.tglDiserahkanTukangSablon) : null,
      tglDiserahkanTukangCmtAnt: record.tglDiserahkanTukangCmt ? dayjs(record.tglDiserahkanTukangCmt) : null,
      tglDiserahkanTukangCmtCelanaAnt: record.tglDiserahkanTukangCmtCelana ? dayjs(record.tglDiserahkanTukangCmtCelana) : null,
      tglDiserahkanPicAnt: record.tglDiserahkanPic ? dayjs(record.tglDiserahkanPic) : null,
    }

    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this PO?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(poApi.deletePo(record.id));
    if (response.status === 200) {
      resetForm();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const showDeleteStokConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this stok?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataStok(record);
      },
    });
  };

  const deleteDataStok = async (record) => {
    setLoadingStok(true);
    const response = await dispatch(poApi.deletePoStok(record.id));
    if (response.status === 200) {
      resetFormStok();
      if (tableParamsStok.pagination.current > 1) {
        setTableParamsStok({
          ...tableParamsStok,
          pagination: {
            ...tableParamsStok.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataStok();
        fetchStokCalculation();
      }
      openNotification('Success', 'Success to delete stok!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingStok(false);
  };

  const showDeleteGambarConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this gambar?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataGambar(record);
      },
    });
  };

  const deleteDataGambar = async (record) => {
    setLoadingGambar(true);
    const response = await dispatch(poApi.deletePoGambar(record.id));
    if (response.status === 200) {
      resetFormStok();
      if (tableParamsGambar.pagination.current > 1) {
        setTableParamsGambar({
          ...tableParamsGambar,
          pagination: {
            ...tableParamsGambar.pagination,
            current: 1,
          }
        });
      } else {
        fetchPoGambar();
      }
      fetchTotalLusin();
      openNotification('Success', 'Success to delete gambar!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingGambar(false);
  };

  const openPreviewImages = (record) => {
    let urls = [];
    record.images.map(obj => {
      let result = BASE_URL + obj;
      urls.push(result);
    })
    setPreviewImages({
      ...previewImages,
      visible: true,
      items: urls,
    });
  }

  const onEditStok = (record) => {
    setSelectedDataStok({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });
    formStok.setFieldsValue({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });
    setIsOpenModalStok(true);
  }

  const onGantiGambar = (record) => {
    setSelectedDataGambar({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    formGambar.setFieldsValue({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    setIsOpenModalGantiGambar(true);
  }

  const onEditGambar = (record) => {
    setSelectedDataGambar({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    formGambar.setFieldsValue({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    setIsOpenModalGambar(true);
  }

  const add = async () => {
    const response = await dispatch(poApi.initPo());
    if (response.status === 200) {
      setMode("Edit");
      let data = response.data;
      data = {
        ...data,
        statusProduksi: "Draft",
        init: true,
        tanggalPotongAnt:null,
      }
      setSelectedData(data);
      form.setFieldsValue(data);
      setIsOpenModal(true);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const addStok = async () => {
    resetFormStok();
    setIsOpenModalStok(true);
  }

  const onCancel = () => {
    resetForm();
    fetchData();
    setIsOpenModal(false);
  };

  const onCancelStok = () => {
    resetFormStok();
    fetchDataStok();
    setIsOpenModalStok(false);
    fetchStokCalculation();
  };

  const onCancelGambar = () => {
    fetchPoGambar();
    fetchTotalLusin();
  }

  const onSaveDraft = () => {
    form.validateFields()
    .then(data => {
      let values = data;
      values = {
        ...values,
        id: selectedData.id,
        tanggalPotong: values.tanggalPotongAnt?.format('YYYY-MM-DD'),
        tglDiserahkanTukangPotong: values.tglDiserahkanTukangPotongAnt?.format('YYYY-MM-DD'),
        tglDiserahkanTukangBordir: values.tglDiserahkanTukangBordirAnt?.format('YYYY-MM-DD'),
        tglDiserahkanTukangSablon: values.tglDiserahkanTukangSablonAnt?.format('YYYY-MM-DD'),
        tglDiserahkanTukangCmt: values.tglDiserahkanTukangCmtAnt?.format('YYYY-MM-DD'),
        tglDiserahkanTukangCmtCelana: values.tglDiserahkanTukangCmtCelanaAnt?.format('YYYY-MM-DD'),
        tglDiserahkanPic: values.tglDiserahkanPicAnt?.format('YYYY-MM-DD'),
        action: 1,
      }
      postPo(values);
    })
    .catch(errorInfo => {
      console.log('Failed:', errorInfo);
    });
  }

  const onFinish = (values) => {
    values = {
      ...values,
      id: selectedData.id,
      tanggalPotong: values.tanggalPotongAnt.format('YYYY-MM-DD'),
      tglDiserahkanTukangPotong: values.tglDiserahkanTukangPotongAnt?.format('YYYY-MM-DD'),
      tglDiserahkanTukangBordir: values.tglDiserahkanTukangBordirAnt?.format('YYYY-MM-DD'),
      tglDiserahkanTukangSablon: values.tglDiserahkanTukangSablonAnt?.format('YYYY-MM-DD'),
      tglDiserahkanTukangCmt: values.tglDiserahkanTukangCmtAnt?.format('YYYY-MM-DD'),
      tglDiserahkanTukangCmtCelana: values.tglDiserahkanTukangCmtCelanaAnt?.format('YYYY-MM-DD'),
      tglDiserahkanPic: values.tglDiserahkanPicAnt?.format('YYYY-MM-DD'),
      action: (mode === "Edit" ? 2 : 3),
    }
    postPo(values);
  };

  const onFinishStok = async (values) => {
    setIsOpenModalStok(false);
    let payload = values;
    payload = {
      ...payload,
      id: selectedDataStok.id,
      poId: selectedData.id,
      jenisSettingBahan: payload.settingBahan.split(" ")[1],
      settingBahan: payload.settingBahan.split(" ")[0],
    }
    const response = await dispatch(poApi.postStok(payload));
    if (response.status === 200) {
      fetchDataStok();
      fetchStokCalculation();
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalStok(true);
    }
  }

  const postPo = async (payload) => {
    if (!selectedData.init) {
      setIsOpenModal(false);
    }
    const response = await dispatch(poApi.post(payload));
    if (response.status === 200) {
      if (selectedData.init === true) {
        setSelectedData({
          ...selectedData,
          init: false,
        });
      } else {
        resetForm();
        fetchData();
      }
      openNotification('Success', 'Success to edit po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    }
  }

  const fetchPoGambar = async () => {
    setLoadingGambar(true);
    if (selectedData.id == null) {
      setLoadingGambar(false);
      return;
    }

    var sortField = tableParamsGambar.sortField === undefined ? "id" : tableParamsGambar.sortField;
    var sortOrder = tableParamsGambar.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageGambar({
      "page": tableParamsGambar.pagination.current,
      "size": tableParamsGambar.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: "poId",
          value: selectedData.id
        }
      ]
    }));
    if (response.status === 200) {
      setDataGambar(response.data.contents);
      setTableParamsGambar({
        ...tableParamsGambar,
        pagination: {
          ...tableParamsGambar.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingGambar(false);
  }

  const addGambar = async () => {
    if (selectedGambarOpt.length > 3) {
      openNotification('Error', 'Tidak dapat menambahkan lebih dari 3 gambar', 'error');
      return;
    }
    setIsOpenModalPilihGambar(false);
    const response = await dispatch(poApi.addGambar(selectedData.id, selectedGambarOpt));
    if (response.status === 200) {
      fetchPoGambar();
      setSelectedGambarOpt([]);
      setGambarSearchValue('');
      openNotification('Success', 'Success to add gambar po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    }
  }

  const updateGambarPo = async () => {
    setIsOpenModalGantiGambar(false);
    let gambarInfo = selectedGambarOpt[0].split("__");
    let payload = {
      id: selectedDataGambar.id,
      kodeGambar: gambarInfo[0],
      namaGambar: gambarInfo[1],
      urlGambar: gambarInfo[2],
    }
    const response = await dispatch(poApi.updateGambarPo(payload));
    if (response.status === 200) {
      fetchPoGambar();
      setSelectedGambarOpt([]);
      setGambarSearchValue('');
      openNotification('Success', 'Success to ganti gambar po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModalGantiGambar(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalGantiGambar(true);
    }
  }

  const addLusinan = async () => {
    setLoadingGambar(true);
    const response = await dispatch(poApi.initGambar(selectedData.id));
    if (response.status === 200) {
      fetchPoGambar();
      openNotification('Success', 'Success to add lusinan po!', 'success');
      setLoadingGambar(false);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    }
  }

  const updateGambar = async (values) => {
    setIsOpenModalGambar(false);
    values = {
      ...selectedDataGambar,
      aksesoris: values.aksesoris?.join("$0$"),
      variasi: values.variasi?.join("$0$"),
    }
    const response = await dispatch(poApi.updateGambar(values));
    if (response.status === 200) {
      fetchPoGambar();
      fetchTotalLusin();
      openNotification('Success', 'Success to update gambar po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalGambar(true);
    }
  }

  const onChangeDropdown = (tipe, value) => {
    let list = filterDropdownMasterBahan;
    if (tipe === "jenis-bahan") {
      list = list.map(obj => {
        if (obj.field === "jenis") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "ketebalan-bahan") {
      list = list.map(obj => {
        if (obj.field === "ketebalan") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "gramasi") {
      list = list.map(obj => {
        if (obj.field === "gramasi") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "warna-bahan") {
      list = list.map(obj => {
        if (obj.field === "warna") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "setting-bahan") {
      list = list.map(obj => {
        if (obj.field === "setting") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } 

    setFilterDropdownMasterBahan(list);
  }

  const reassignSize = (id, value) => {
    let selectedDataNew = selectedDataGambar;
    let total = 0;
    selectedDataNew.sizes.map(obj => {
      if (obj.id === id) {
        obj.jumlah = value;
      }
    });
    selectedDataNew.sizes.map(obj => {
      total += obj.jumlah;
    });

    selectedDataNew = {
      ...selectedDataNew,
      jumlahLusin: total,
    }

    formGambar.setFieldValue('jumlahLusin', total);

    setSelectedDataGambar(selectedDataNew);
  }

  const print = (record) => {
    let encodedId = btoa(record.id);
    window.open(BASE_URL + "/v1/generate/report/po/Purchase Order - " + record.noPo + "?id=" + encodedId);
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataStok();
  }, [
    selectedData.id,
    tableParamsStok.pagination?.current,
    tableParamsStok.pagination?.pageSize,
    tableParamsStok?.sortOrder,
    tableParamsStok?.sortField,
  ]);

  useEffect(() => {
    fetchPoGambar();
  }, [
    selectedData.id,
    tableParamsGambar.pagination?.current,
    tableParamsGambar.pagination?.pageSize,
    tableParamsGambar?.sortOrder,
    tableParamsGambar?.sortField,
  ]);

  useEffect(() => {
    fetchToko();
    fetchMerek();
    fetchJenisPakaian();
    fetchAksesoris();
    fetchVariasi();
  }, []);

  useEffect(() => {
    fetchGambar();
  }, [gambarSearchValue]);

  useEffect(() => {
    fetchDropdown("jenis-bahan");
    fetchDropdown("ketebalan-bahan");
    fetchDropdown("gramasi");
    fetchDropdown("warna-bahan");
    fetchDropdown("setting-bahan");
  }, [
    filterDropdownMasterBahan
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 180 }}
            iconPosition='start'
            onClick={add}
          >
            Add PO
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title={selectedData.noPo === null ? "Add Purchase Order (PO)" : (mode === "View" ? "View Purchase Order (PO)" : "Edit Purchase Order (PO)")}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          name="po-form"
          className="po-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="No. Purchase Order (PO)"
              name="noPo"
              style={{width: '494px'}}
            >
              <Input size="large" disabled placeholder='Generated automated'/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Toko"
              name="namaToko"
              style={{width: '350px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama toko',
                },
              ]}
            >
              <Select 
                showSearch
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Pilih nama toko"
                options={tokoOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Merek"
              name="merek"
              style={{width: '494px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih merek',
                },
              ]}
            >
              <Select 
                disabled={selectedData.statusProduksi !== "Draft"}
                showSearch
                placeholder="Pilih merek"
                options={merekOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Tanggal Potong"
              name="tanggalPotongAnt"
              style={{width: '350px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih tanggal potong',
                },
              ]}
            >
              <DatePicker 
                disabled={selectedData.statusProduksi !== "Draft"}
                size='large'
                style={{width: '100%'}}
              />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenisPakaian"
              style={{width: '350px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih jenis pakaian',
                },
              ]}
            >
              <Select 
                showSearch
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Pilih jenis pakaian"
                options={jenisPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(value) => {
                  form.setFieldsValue({
                    namaPakaian: "",
                  });
                  fetchNamaPakaian(value);

                  if (value !== selectedData.jenisPakaian) {
                    setSelectedData({
                      ...selectedData,
                      init: true,
                    });
                  } else {
                    setSelectedData({
                      ...selectedData,
                      init: false,
                    });
                  }
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="namaPakaian"
              style={{width: '494px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama pakaian',
                },
              ]}
            >
              <Select 
                showSearch
                disabled={mode === "View" || namaPakaianOpt.length === 0 || selectedData.statusProduksi !== "Draft"}
                placeholder="Pilih nama pakaian"
                options={namaPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  if (value !== selectedData.namaPakaian) {
                    setSelectedData({
                      ...selectedData,
                      init: true,
                    });
                  } else {
                    setSelectedData({
                      ...selectedData,
                      init: false,
                    });
                  }
                }}
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>
          
          {
            selectedData.init !== true &&
            <>
            <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
              {
                mode !== "View" && selectedData.statusProduksi === "Draft" &&
                <Button 
                  size="large" 
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ width: 180, marginBottom: '8px' }}
                  iconPosition='start'
                  onClick={addStok}
                >
                  Add Stok
                </Button>
              }
            </Space>

            <Table
              columns={tableColumnsStok}
              rowKey={(record) => record.id}
              dataSource={dataStok}
              pagination={tableParamsStok.pagination}
              loading={{
                spinning: loadingStok,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableChangeStok}
            />

            <Form.Item
              layout="vertical"
              label="Total Berat Kain (Kg)"
              name="totalBeratKain"
              style={{width: '100%', marginTop: '12px'}}
            >
              <InputNumber size="large" disabled
                placeholder='Total berat kain (kg)'
                min={1} style={{width: '100%'}} />
            </Form.Item>

            <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
              {
                mode !== "View" && selectedData.statusProduksi === "Draft" &&
                <Button 
                  size="large" 
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ width: 180, marginBottom: '8px' }}
                  iconPosition='start'
                  onClick={() => {setIsOpenModalPilihGambar(true)}}
                >
                  Add Gambar
                </Button>
              }
              {
                mode !== "View" && selectedData.statusProduksi === "Draft" &&
                <Button 
                  size="large" 
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ width: 180, marginBottom: '8px' }}
                  iconPosition='start'
                  onClick={addLusinan}
                >
                  Add Lusinan
                </Button>
              }
            </Space>

            <Table
              columns={tableColumnsGambar}
              rowKey={(record) => record.id}
              dataSource={dataGambar}
              pagination={tableParamsGambar.pagination}
              loading={{
                spinning: loadingGambar,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableChangeGambar}
            />

            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Lusin"
              name="totalLusin"
              style={{width: '100%', marginTop: '12px'}}
            >
              <InputNumber size="large" disabled
                placeholder='Total Keseluruhan Lusin'
                min={1} style={{width: '100%'}} />
            </Form.Item>

            <Divider />

            <h3 style={{textAlign: 'center'}}>Paraf & Keterangan</h3>

            <label style={{fontWeight:'bold'}}>Tukang Potong</label>
            <Form.Item
              layout="vertical"
              label=""
              name="isDiserahkanTukangPotong"
              style={{marginBottom: '8px'}}
            >
              <Checkbox 
                checked={selectedData.isDiserahkanTukangPotong}
                disabled={selectedData.isTukangPotongDone && selectedData.statusProduksi !== "Draft"} 
                onChange={(e) => {
                  form.setFieldValue('isDiserahkanTukangPotong', e.target.checked);
                  setSelectedData({
                    ...selectedData,
                    isDiserahkanTukangPotong: e.target.checked,
                  });
                  if (!e.target.checked) {
                    form.setFieldValue('namaTukangPotong', null);
                    form.setFieldValue('tglDiserahkanTukangPotong', null);
                    form.setFieldValue('tglDiserahkanTukangPotongAnt', null);
                  }
                }}
              >Sudah Diserahkan</Checkbox>
            </Form.Item>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangPotong"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangPotong,
                    message: 'Masukkan nama tukang potong',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama tukang potong'
                  disabled={selectedData.isTukangPotongDone && selectedData.statusProduksi !== "Draft"} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangPotongAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangPotong,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.isTukangPotongDone && selectedData.statusProduksi !== "Draft"}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <label style={{fontWeight:'bold', width: '100%'}}>Sablon</label>
            <Space style={{width: '100%'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiperlukanTukangSablon"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiperlukanTukangSablon}
                  disabled={(selectedData.isTukangSablonDone && selectedData.statusProduksi !== "Draft") || selectedData.statusProduksi === "Done"} 
                  onChange={(e) => {
                    form.setFieldValue('isDiperlukanTukangSablon', e.target.checked);
                    if (!e.target.checked) {
                      form.setFieldValue('isDiserahkanTukangSablon', false);
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangSablon: e.target.checked,
                        isDiserahkanTukangSablon: false,
                      });
                      form.setFieldValue('isDiserahkanTukangSablon', false);
                      form.setFieldValue('namaTukangSablon', null);
                      form.setFieldValue('tglDiserahkanTukangSablon', null);
                      form.setFieldValue('tglDiserahkanTukangSablonAnt', null);
                    } else {
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangSablon: e.target.checked,
                      });
                    }
                  }}
                >Diperlukan</Checkbox>
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiserahkanTukangSablon"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiserahkanTukangSablon}
                  disabled={(selectedData.isTukangSablonDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangSablon} 
                  onChange={(e) => {
                    form.setFieldValue('isDiserahkanTukangSablon', e.target.checked);
                    setSelectedData({
                      ...selectedData,
                      isDiserahkanTukangSablon: e.target.checked,
                    });
                    if (!e.target.checked) {
                      form.setFieldValue('namaTukangSablon', null);
                      form.setFieldValue('tglDiserahkanTukangSablon', null);
                      form.setFieldValue('tglDiserahkanTukangSablonAnt', null);
                    }
                  }}
                >Sudah Diserahkan</Checkbox>
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangSablon"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangSablon,
                    message: 'Masukkan nama tukang sablon',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama tukang sablon'
                  disabled={(selectedData.isTukangSablonDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangSablon} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangSablonAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangSablon,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={(selectedData.isTukangSablonDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangSablon}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <label style={{fontWeight:'bold', width: '100%'}}>Bordir</label>
            <Space style={{width: '100%'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiperlukanTukangBordir"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiperlukanTukangBordir}
                  disabled={(selectedData.isTukangBordirDone && selectedData.statusProduksi !== "Draft") || selectedData.statusProduksi === "Done"} 
                  onChange={(e) => {
                    form.setFieldValue('isDiperlukanTukangBordir', e.target.checked);
                    if (!e.target.checked) {
                      form.setFieldValue('isDiserahkanTukangBordir', false);
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangBordir: e.target.checked,
                        isDiserahkanTukangBordir: false,
                      });
                      form.setFieldValue('isDiserahkanTukangBordir', false);
                      form.setFieldValue('namaTukangBordir', null);
                      form.setFieldValue('tglDiserahkanTukangBordir', null);
                      form.setFieldValue('tglDiserahkanTukangBordirAnt', null);
                    } else {
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangBordir: e.target.checked,
                      });
                    }
                  }}
                >Diperlukan</Checkbox>
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiserahkanTukangBordir"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiserahkanTukangBordir}
                  disabled={(selectedData.isTukangBordirDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangBordir} 
                  onChange={(e) => {
                    form.setFieldValue('isDiserahkanTukangBordir', e.target.checked);
                    setSelectedData({
                      ...selectedData,
                      isDiserahkanTukangBordir: e.target.checked,
                    });
                    if (!e.target.checked) {
                      form.setFieldValue('namaTukangBordir', null);
                      form.setFieldValue('tglDiserahkanTukangBordir', null);
                      form.setFieldValue('tglDiserahkanTukangBordirAnt', null);
                    }
                  }}
                >Sudah Diserahkan</Checkbox>
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangBordir"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangBordir,
                    message: 'Masukkan nama tukang bordir',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama tukang bordir'
                  disabled={(selectedData.isTukangBordirDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangBordir} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangBordirAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangBordir,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={(selectedData.isTukangBordirDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangBordir}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <label style={{fontWeight:'bold'}}>Cut Make Trim (CMT)</label>
            <Form.Item
              layout="vertical"
              label=""
              name="isDiserahkanTukangCmt"
              style={{marginBottom: '8px'}}
            >
              <Checkbox 
                checked={selectedData.isDiserahkanTukangCmt}
                disabled={selectedData.isTukangCmtDone && selectedData.statusProduksi !== "Draft"} 
                onChange={(e) => {
                  form.setFieldValue('isDiserahkanTukangCmt', e.target.checked);
                  setSelectedData({
                    ...selectedData,
                    isDiserahkanTukangCmt: e.target.checked,
                  });
                  if (!e.target.checked) {
                    form.setFieldValue('namaTukangCmt', null);
                    form.setFieldValue('tglDiserahkanTukangCmt', null);
                    form.setFieldValue('tglDiserahkanTukangCmtAnt', null);
                  }
                }}
              >Sudah Diserahkan</Checkbox>
            </Form.Item>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangCmt"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangCmt,
                    message: 'Masukkan nama tukang cmt',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama tukang cmt'
                  disabled={selectedData.isTukangCmtDone && selectedData.statusProduksi !== "Draft"} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangCmtAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangCmt,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.isTukangCmtDone && selectedData.statusProduksi !== "Draft"}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <label style={{fontWeight:'bold', width: '100%'}}>Cut Make Trim (CMT) Celana</label>
            <Space style={{width: '100%'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiperlukanTukangCmtCelana"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiperlukanTukangCmtCelana}
                  disabled={(selectedData.isTukangCmtCelanaDone && selectedData.statusProduksi !== "Draft") || selectedData.statusProduksi === "Done"} 
                  onChange={(e) => {
                    form.setFieldValue('isDiperlukanTukangCmtCelana', e.target.checked);
                    if (!e.target.checked) {
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangCmtCelana: e.target.checked,
                        isDiserahkanTukangCmtCelana: false,
                      });
                      form.setFieldValue('isDiserahkanTukangCmtCelana', false);
                      form.setFieldValue('namaTukangCmtCelana', null);
                      form.setFieldValue('tglDiserahkanTukangCmtCelana', null);
                      form.setFieldValue('tglDiserahkanTukangCmtCelanaAnt', null);
                    } else {
                      setSelectedData({
                        ...selectedData,
                        isDiperlukanTukangCmtCelana: e.target.checked,
                      });
                    }
                  }}
                >Diperlukan</Checkbox>
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="isDiserahkanTukangCmtCelana"
                style={{marginBottom: '8px'}}
              >
                <Checkbox 
                  checked={selectedData.isDiserahkanTukangCmtCelana}
                  disabled={(selectedData.isTukangCmtCelanaDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangCmtCelana} 
                  onChange={(e) => {
                    form.setFieldValue('isDiserahkanTukangCmtCelana', e.target.checked);
                    setSelectedData({
                      ...selectedData,
                      isDiserahkanTukangCmtCelana: e.target.checked,
                    });
                    if (!e.target.checked) {
                      form.setFieldValue('namaTukangCmtCelana', null);
                      form.setFieldValue('tglDiserahkanTukangCmtCelana', null);
                      form.setFieldValue('tglDiserahkanTukangCmtCelanaAnt', null);
                    }
                  }}
                >Sudah Diserahkan</Checkbox>
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangCmtCelana"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangCmtCelana,
                    message: 'Masukkan nama tukang cmt celana',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama tukang cmt celana'
                  disabled={(selectedData.isTukangCmtCelanaDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangCmtCelana} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangCmtCelanaAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanTukangCmtCelana,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={(selectedData.isTukangCmtCelanaDone && selectedData.statusProduksi !== "Draft") || !selectedData.isDiperlukanTukangCmtCelana}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <label style={{fontWeight:'bold'}}>Diketahui Oleh</label>
            <Form.Item
              layout="vertical"
              label=""
              name="isDiserahkanPic"
              style={{marginBottom: '8px'}}
            >
              <Checkbox 
                checked={selectedData.isDiserahkanPic}
                disabled={selectedData.isPicDone && selectedData.statusProduksi !== "Draft"} 
                onChange={(e) => {
                  form.setFieldValue('isDiserahkanPic', e.target.checked);
                  setSelectedData({
                    ...selectedData,
                    isDiserahkanPic: e.target.checked,
                  });
                  if (!e.target.checked) {
                    form.setFieldValue('namaPic', null);
                    form.setFieldValue('tglDiserahkanPic', null);
                    form.setFieldValue('tglDiserahkanPicAnt', null);
                  }
                }}
              >Sudah Diserahkan</Checkbox>
            </Form.Item>
            <Space>
              <Form.Item
                layout="vertical"
                label=""
                name="namaPic"
                style={{width: '494px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanPic,
                    message: 'Masukkan nama penanggung jawab',
                  },
                ]}
              >
                <Input size="large" placeholder='Nama penanggung jawab'
                  disabled={selectedData.isPicDone && selectedData.statusProduksi !== "Draft"} />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanPicAnt"
                style={{width: '350px'}}
                rules={[
                  {
                    required: selectedData.isDiserahkanPic,
                    message: 'Pilih tanggal diserahkan',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.isPicDone && selectedData.statusProduksi !== "Draft"}
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>
            </>
          }


          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                {selectedData.statusProduksi !== "Done" ? "Cancel" : "Close"}
              </Button>
              {
                selectedData.statusProduksi === "Draft" && 
                <Button type={selectedData.init === true ? "primary" : ""} htmlType="button" style={{marginRight: '8px'}} size="large" onClick={onSaveDraft}>
                  {selectedData.init === true ? "Save to Continue" : "Save Draft"}
                </Button>
              }
              {
                selectedData.statusProduksi !== "Done" && selectedData.init !== true &&
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
              }
            </Form.Item>
          </Space>
        </Form>
      </Modal>

      <Modal
        title={selectedDataStok.id === null ? "Add Stok" : (mode === "View" || selectedData.statusProduksi !== "Draft" ? "View Stok" : "Edit Stok")}
        open={isOpenModalStok}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={formStok}
          name="stok-form"
          className="stok-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinishStok}
        >
          <Form.Item
            layout="vertical"
            label="Jenis Bahan"
            name="jenisBahan"
            style={{width: '652px'}}
            rules={[
              {
                required: true,
                message: 'Please select jenis bahan',
              },
            ]}
          >
            <Select 
              showSearch
              allowClear
              disabled={selectedData.statusProduksi !== "Draft"}
              placeholder="Select jenis bahan"
              options={dropdownJenisBahan} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" 
              onChange={(value) => {
                onChangeDropdown("jenis-bahan", value);
              }}/>
          </Form.Item>

          <Space>
            <Form.Item
              layout="vertical"
              label="Ketebalan Bahan"
              name="ketebalanBahan"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select ketebalan bahan',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Select ketebalan bahan"
                options={dropdownKetebalanBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("ketebalan-bahan", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Gramasi"
              name="gramasi"
              style={{width: '211px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select gramasi',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Select gramasi"
                options={dropdownGramasiBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("gramasi", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Warna Bahan"
              name="warnaBahan"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select warna bahan',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Select warna bahan"
                options={dropdownWarnaBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("warna-bahan", value);
                }}
              />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Setting Bahan"
              name="settingBahan"
              style={{width: '322px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select setting bahan',
                },
              ]}
            >
              <Select 
                showSearch
                allowClear
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder="Select setting bahan"
                options={dropdownSettingBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("setting-bahan", value);
                }}/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Berat Kain (Kg)"
              name="beratKain"
              style={{width: '322px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input berat kain (kg)',
                },
              ]}
            >
              <InputNumber size="large" 
                disabled={selectedData.statusProduksi !== "Draft"}
                placeholder='Input berat kain (kg)'
                min={1} style={{width: '100%'}} />
            </Form.Item>
          </Space>

          
          <Form.Item
            layout="vertical"
            label="Keterangan"
            name="keterangan"
          >
            <Input.TextArea 
              disabled={selectedData.statusProduksi !== "Draft"}
              autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancelStok}> 
                {selectedData.statusProduksi === "Draft" ? "Cancel" : "Close"}
              </Button>
              {
                selectedData.statusProduksi === "Draft" && 
                <Button type="primary" htmlType="submit" size="large">
                  {selectedDataStok.id === null ? "Add" : "Edit"}
                </Button>
              }
            </Form.Item>
          </Space>
        </Form>
      </Modal>

      <Modal
        title="Add Gambar"
        open={isOpenModalPilihGambar}
        width={785}
        closable={false}
        footer={[]}
      >
        <Input 
          placeholder="Cari kode gambar atau nama gambar"
          size='large'
          value={gambarSearchValue} onChange={(e) => {setGambarSearchValue(e.target.value)}}
          style={{marginBottom: '16px'}} />
        <Space style={{flexWrap:'wrap', maxHeight: '450px', overflowY: 'scroll'}}>
          {
            gambarOpt.map(obj => (
              <Card
                key={obj.kodeGambar}
                hoverable
                style={{
                  width: 230
                }}
                cover={
                  <Image
                    height={150}
                    style={{objectFit: 'contain'}}
                    src={obj.urlGambar}
                  />
                }
              >
                <Meta title={obj.kodeGambar} description={obj.namaGambar} />
                <Checkbox 
                  className='card-image-checkbox' 
                  checked={selectedGambarOpt.includes(obj.kodeGambar + "__" + obj.namaGambar + "__" + obj.urlGambar)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      let temp = [obj.kodeGambar + "__" + obj.namaGambar + "__" + obj.urlGambar];
                      let combinedList = [...new Set([...selectedGambarOpt, ...temp])];
                      setSelectedGambarOpt(combinedList);
                    } else {
                      let updatedList = selectedGambarOpt.filter(gambar => gambar !== (obj.kodeGambar + "__" + obj.namaGambar + "__" + obj.urlGambar));
                      setSelectedGambarOpt(updatedList);
                    }
                  }} />
              </Card>
            ))
          }
        </Space>
        <Space
          align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
        >
          <Form.Item>
            <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={() => {
              setIsOpenModalPilihGambar(false);
              setGambarSearchValue('');
              setSelectedGambarOpt([]);
            }}> 
              Close
            </Button>
            <Button type="primary" htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={addGambar}> 
              Add
            </Button>
          </Form.Item>
        </Space>
      </Modal>

      <Modal
        title={mode === "View" || selectedData.statusProduksi !== "Draft" ? "View Detail" : "Edit Detail"}
        open={isOpenModalGambar}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={formGambar}
          name="gambar-form"
          className="gambar-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={updateGambar}
        >

          <Form.Item
            layout="vertical"
            label="Kode Gambar"
            name="kodeGambar"
          >
            <Input size="large" placeholder='Kode gambar' disabled />
          </Form.Item>
          <Space>
            <Space style={{marginBottom: '16px', border:'1px solid #b8ded8', borderRadius: '8px'}}>
              <Image
                alt={selectedDataGambar.namaGambar === null ? "no-image": selectedDataGambar.namaGambar}
                width={284}
                height={284}
                style={{objectFit: 'contain', padding: '12px'}}
                src={selectedDataGambar.urlGambar === null ? noImage : BASE_URL + selectedDataGambar.urlGambar}
              />
            </Space>
            <Space style={{display: 'block'}}>
            {
              selectedDataGambar.sizes.map(obj => (
                <Space key={obj.id}>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"size" +obj.id}
                    initialValue={obj.ukuran}
                    style={{width: '100px'}}
                  >
                    <Input size="large" placeholder='Ukuran' disabled />
                  </Form.Item>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"jumlah"+obj.id}
                    initialValue={obj.jumlah}
                    style={{width: '250px'}}
                    rules={[
                      {
                        required: true,
                        message: 'Masukkan jumlah',
                      },
                    ]}
                  >
                    <InputNumber 
                      style={{width: '100%'}} 
                      size="large" 
                      placeholder='Total jumlah Lusin' 
                      disabled={selectedData.statusProduksi !== "Draft"} 
                      onChange={(value) => {
                        reassignSize(obj.id, value);
                      }}
                    />
                  </Form.Item>
                </Space>
              ))
            }
            </Space>
          </Space>

          <Form.Item
            layout="vertical"
            label="Total Jumlah Lusin"
            name="jumlahLusin"
            rules={[
              {
                required: true,
                message: 'Masukkan jumlah lusin',
              },
            ]}
          >
            <InputNumber style={{width: '100%'}} size="large" placeholder='Total jumlah Lusin' disabled />
          </Form.Item>

          <Form.Item
            layout="vertical"
            label="Variasi"
            name="variasi"
          >
            <Select 
              showSearch
              disabled={selectedData.statusProduksi !== "Draft"}
              mode="multiple"
              placeholder="Tambah Variasi"
              options={variasiOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>

          <Form.Item
            layout="vertical"
            label="Aksesoris"
            name="aksesoris"
          >
            <Select 
              showSearch
              disabled={selectedData.statusProduksi !== "Draft"}
              mode="multiple"
              placeholder="Tambah Aksesoris"
              options={aksesorisOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
              onClick={() => {
                setIsOpenModalGambar(false);
                resetFormGambar();
                fetchTotalLusin();
              }}> 
                Close
              </Button>
              {
                selectedData.statusProduksi === "Draft" && 
                <Button type="primary" htmlType="submit" size="large" style={{marginRight: '8px'}}> 
                  Edit
                </Button>
              }
            </Form.Item>
          </Space>    
        </Form>    
      </Modal>
      <Image.PreviewGroup
        preview={{
          visible: previewImages.visible,
          onVisibleChange: (value) => {
            setPreviewImages({
              ...previewImages,
              visible: value,
            });
          },
        }}
        items={previewImages.items}
      />

      <Modal
        title="Ganti Gambar"
        open={isOpenModalGantiGambar}
        width={785}
        closable={false}
        footer={[]}
      >
        <Input 
          placeholder="Cari kode gambar atau nama gambar"
          size='large'
          value={gambarSearchValue} onChange={(e) => {setGambarSearchValue(e.target.value)}}
          style={{marginBottom: '16px'}} />
        <Space style={{flexWrap:'wrap', maxHeight: '450px', overflowY: 'scroll'}}>
          <Radio.Group style={{display: 'inline-flex', gap: '18px', flexWrap: 'wrap'}} 
            onChange={(e) => {setSelectedGambarOpt([e.target.value]);}}
          >
          {
            gambarOpt.map(obj => (
              <Card
                key={obj.kodeGambar}
                hoverable
                style={{
                  width: 230
                }}
                cover={
                  <Image
                    height={150}
                    style={{objectFit: 'contain'}}
                    src={obj.urlGambar}
                  />
                }
              >
                <Meta title={obj.kodeGambar} description={obj.namaGambar} />
                <Radio value={obj.kodeGambar + "__" + obj.namaGambar + "__" + obj.urlGambar} className='card-image-checkbox'></Radio>
              </Card>
            ))
          }
          </Radio.Group>
        </Space>
        <Space
          align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
        >
          <Form.Item>
            <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={() => {
              setIsOpenModalGantiGambar(false);
              setGambarSearchValue('');
              setSelectedGambarOpt([]);
            }}> 
              Close
            </Button>
            <Button type="primary" htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={updateGambarPo}> 
              Ganti
            </Button>
          </Form.Item>
        </Space>
      </Modal>
    </Context.Provider>
  )
}

const PO = () => {
  return (
    <MasterLayout
      pageName="Purchase Order (PO) - Produksi"
      activeMenu="po-produksi" 
      activeParent="po"
      content={Content()}
    />
  )
}

export default PO;